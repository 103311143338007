import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { EnumHelper } from '@shared/helpers/enum-helper';
import { PriorityEnum } from '@shared/models/enums/priority-enum';
import { StatusEnum } from '@shared/models/enums/status-enum';
import { TypeEnum } from '@shared/models/enums/type-enum';
import { NotificationDto } from '@shared/models/notification.dto';
import { PushNotifcationsService } from '@shared/services/push-notifications.service';
import { selectNotifications } from '@store/notifications/notifications-selectors';
import { StoreState } from '@store/store-state';
import { filter } from 'rxjs/internal/operators/filter';
import { EditReportComponent } from 'src/app/report/edit-report/edit-report.component';
import { ReportHistoryComponent } from 'src/app/report/report-history/report-history.component';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  notifications: NotificationDto[];
  typeNames =  [];
  priorityNames = [];
  statusNames = [];
  guidRegex = /[A-Za-z0-9]{8}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{12}/g;
  constructor(private store$: Store<StoreState>, private pushNotifcationsService: PushNotifcationsService,
    private dialog: MatDialog,
    ) {
    this.getEnumValues();

  }

  ngOnInit(): void {
    this.store$
      .pipe(
        select(selectNotifications),
        filter((_) => _ != null)
      )
      .subscribe((data) => {
        this.notifications = data;
      });

    const element = document.getElementById('notificationPanel');
    element.scrollTop = element.scrollHeight;
  }

  getGuidParameter(notification: NotificationDto){
    this.guidRegex.lastIndex = 0;
    var match = this.guidRegex.exec(notification.parameter);
    return match ? match[0] : null;
  }

  getEnumValues(){
    this.typeNames = EnumHelper.getEnumKeys(TypeEnum)
    this.priorityNames = EnumHelper.getEnumKeys(PriorityEnum)
    this.statusNames = EnumHelper.getEnumKeys(StatusEnum);

  }
  getParameters(notification: NotificationDto){
    this.guidRegex.lastIndex = 0;
    var match = this.guidRegex.exec(notification.parameter);
    return match ? notification.parameter.replace(match[0], ' ') : notification.parameter;
  }

  showHistory(reportId: string) {
    const dialogRef = this.dialog.open(ReportHistoryComponent, {
      minWidth: '500px',
      height: '60%',
      disableClose: false,
      closeOnNavigation: true,
      data: { report:null,types:this.typeNames,priorities:this.priorityNames,statuses:this.statusNames,reportId: reportId },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
      }
    });
  }

  onEdit(reportId: string) {
    const dialogRef = this.dialog.open(EditReportComponent, {
      minWidth: '80vw',
      maxWidth: '80vw',
      maxHeight: '100vh',
      disableClose: true,
      closeOnNavigation: false,
      data: { report:null,types:this.typeNames,priorities:this.priorityNames,statuses:this.statusNames,reportId: reportId },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
      }
    });
  }

}