import { createSelector } from '@ngrx/store';
import { StoreState } from "@store/store-state";
import { State } from './module.reducer';

const selectModulesFeature = (state: StoreState) => state.modules;


export const selectModulesSearchModel = createSelector(
    selectModulesFeature,
    (state: State) => state.modulesSearchModel
  );

  export const selectPageableModules = createSelector(selectModulesFeature, (state: State) => state.pageableModules);

  export const selectModules = createSelector(selectModulesFeature, (state: State) => {
    return state.modules;
  });