export class FileInfoDto {
  constructor(data: FileInfoDto) {
    this.id = data.id;
    this.fileName = data.fileName;
    this.canDelete = data.canDelete;
    this.isDeleting = data.isDeleting;
  }
  id: any;
  fileName: string;
  canDelete: boolean;
  isDeleting?: boolean;
}
