<div class="table-container mat-elevation-z8">
  <div [ngStyle]="{ 'height.px': height }">
    <ng-content select="[mat-table]" *ngIf="!loading"></ng-content>
    <div *ngIf="loading" class="center">
      <mat-spinner [diameter]="240"> </mat-spinner>
    </div>
  </div>
</div>
<div class="display-flex">
  <ng-content select="[mat-actions]"></ng-content>
  <div class="flex-spacer"></div>
  <div class="display-flex">
    <!-- <div class="mat-paginator bg-color-transparent">
      <div class="mat-paginator-outer-container">
        <div class="mat-paginator-container">
          <div class="mat-paginator-page-size ng-star-inserted">
            <div class="mat-paginator-page-size-label">{{ 'Common.GoTo' | translate }}</div>
            <mat-form-field class="ml-10 width-50">
              <input
                type="number"
                min="1"
                [max]="totalPages"
                matInput
                [formControl]="paginatorControl"
                (keyup.enter)="goto()"
                (change)="goto()"
              />
              <mat-error>{{ 'ValidationErrors.OutOfRange' | translate }}</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div> -->

    <mat-paginator class="bg-color-transparent" [length]="total" [pageSize]="filters.pageSize"
      [pageSizeOptions]="[5, 10, 25, 100]" [pageIndex]="filters.pageNumber" (page)="pageChange($event)"
      [showFirstLastButtons]="true">
    </mat-paginator>
  </div>
</div>
