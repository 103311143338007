import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarMessagesComponent } from '../widgets/error-message/snack-bar-messages.component';

export type MessageType = 'success' | 'error';

@Injectable({
  providedIn: 'root',
})
export class SnackBarWrapperService {
  constructor(private matSnackBar: MatSnackBar) {}

  public openMessage(message: string, type?: MessageType) {
    const panelClass = type == null ? undefined : this.getPanelClass(type);

    this.matSnackBar.openFromComponent(SnackBarMessagesComponent, { data: { message }, duration: 20000, panelClass });
  }

  private getPanelClass(type: MessageType): string | string[] {
    switch (type) {
      case 'success':
        return 'alert-success';
      case 'error':
        return 'alert-danger';
    }
  }
}
