import { Overlay, OverlayRef, PositionStrategy, ScrollStrategy, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { select, Store } from '@ngrx/store';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { environment } from '@env/environment';
import { StoreState } from '@store/store-state';
import { loadAllClaims, storeUserData } from '@store/user-data/user-data.actions';
import { OverlayWrapperService } from '@shared/overlay/overlay-wrapper.service';
import { ComponentPortal } from '@angular/cdk/portal';
import { NotificationComponent } from '../notification/notification.component';
import { filter } from 'rxjs/internal/operators/filter';
import { selectNotifications } from '@store/notifications/notifications-selectors';
import { loadNotifications, markNotificationsAsReceived } from '@store/notifications/notification.action';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  @Input() snav: MatSidenav;
  isAuthenticated: boolean;
  userData: any;
  user: string;
  isDebug = false;
  roles: string;
  notificationsNumber: number;
  overlayRef: OverlayRef;
  overlayPosition: PositionStrategy;
  notificationComponentPortal: ComponentPortal<NotificationComponent>;
  scrollStrategy: ScrollStrategy;
  open: boolean;
  @ViewChild('notificationsButton') buttonRef: ElementRef;

  constructor(
    private overlayWrapperService: OverlayWrapperService,
    private store$: Store<StoreState>,
    private overlay: Overlay,
    private readonly sso: ScrollStrategyOptions,
    public oidcSecurityService: OidcSecurityService
  ) {
    this.isDebug = environment.production !== true;
    this.scrollStrategy = this.sso.block();
    this.open = false;
  }

  ngOnInit() {
    //this.store$.dispatch(loadNotifications());
    this.store$
      .pipe(
        select(selectNotifications),
        filter((_) => _ != null)
      )
      .subscribe((data) => {
        this.notificationsNumber = Array.isArray(data) ? data.filter((_) => !_.received).length : 0;
      });

      
    this.oidcSecurityService.userData$.subscribe((auth) => {
      this.isAuthenticated = auth;
    });

    this.oidcSecurityService.userData$.subscribe((userData) => {
      if (userData != null) {
        this.userData = userData;
        this.user = userData.email;
        this.store$.dispatch(storeUserData({ data: this.userData }));
        this.store$.dispatch(loadAllClaims());
      }
    });
  }

  public showNotifications() {
    this.store$.dispatch(markNotificationsAsReceived());
    const overlay = this.overlayWrapperService.openAttachedOverlay(
      'attachments',
      this.notificationComponentPortal,
      this.buttonRef,
      'bottom',
      true
    );
    if (overlay == null) {
      this.overlayWrapperService.closeOverlay('attachments');
    }
  }

  login() {
    this.oidcSecurityService.authorize();
  }

  logout() {
    this.oidcSecurityService.logoff();
  }

  ngAfterViewInit() {
    this.notificationComponentPortal = new ComponentPortal(NotificationComponent);
  }
}
