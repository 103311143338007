import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { StoreState } from '../../root-store/store-state';
import { selectClaimsDictionary, selectUserData } from '../../root-store/user-data/user-data-selectors';
import { ArrayHelper } from '../helpers/array-helper';
import { AppPermission } from '../models/enums/app-permission.enum';
import { PermissionDictionaryElement } from '../models/permissionDictionaryElement';
import { UserDataDto } from '../models/user-data.dto';

@Injectable({
  providedIn: 'root',
})
export class SecurityService {
  userData: UserDataDto;
  allClaims: PermissionDictionaryElement[];

  constructor(private store$: Store<StoreState>) {
    this.store$
      .pipe(
        select(selectUserData),
        filter((_) => _ != null)
      )
      .subscribe((data) => {
        // dispatch for that is executed in authorized-layout component
        this.userData = data;
      });

    this.store$
      .pipe(
        select(selectClaimsDictionary),
        filter((_) => _ != null)
      )
      .subscribe((data) => {
        this.allClaims = data;
      });
  }

  hasClaim(claimType: AppPermission): boolean {
    const claimValue = this.allClaims.filter((obj) => {
      return obj.value === claimType;
    });
    const accessAll = this.allClaims.filter((obj) => {
      return obj.name === 'AccessAll';
    });
    if (
      (claimValue.length > 0 && this.userData.enumPermission.includes(claimValue[0].value)) ||
      this.userData.enumPermission.includes(accessAll[0].value)
    ) {
      return true;
    } else {
      return false;
    }
  }

  hasClaims(claims: AppPermission[], respectAccessAll = true): boolean {
    if (this.allClaims == null || this.userData == null) {
      return false;
    }
    if (respectAccessAll) {
      const accessAll = this.allClaims.filter((obj) => {
        return obj.name === 'AccessAll';
      });
      if (this.userData.enumPermission.includes(accessAll[0].value)) {
        return true;
      }
    }

    const claimsValues = this.allClaims.filter((obj) => {
      return claims.includes(obj.value);
    });

    const result = ArrayHelper.arraysIntersects(
      claimsValues.map((_) => _.value),
      this.userData.enumPermission
    );

    return result;
  }
}
