import {
  setPageSize,
  setPageSizeSuccess,
  getPageSize,
  getPageSizeSuccess,
  getPageSizeFailure,
  setPageSizeFailure,
  setOrderBy,
  getOrderBy,
  getOrderBySuccess,
  getOrderByFailure,
  setOrderByFailure,
  setOrderBySuccess,
  setSortType,
  setSortTypeSuccess,
  setSortTypeFailure,
  getSortType,
  getSortTypeSuccess,
  getSortTypeFailure,
  setSearchTerm,
  setSearchTermSuccess,
  setSearchTermFailure,
  getSearchTerm,
  getSearchTermSuccess,
  getSearchTermFailure,
  setSelectedStatuses,
  getSelectedStatuses,
  getSelectedStatusesSuccess,
  setSelectedStatusesSuccess,
  getSelectedStatusesFailure,
  setSelectedStatusesFailure,
} from './tables.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap, map, catchError, tap, withLatestFrom, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { StoreState } from '../store-state';
import { selectPageSize, selectOrderBy, selectSortType, selectSearchTerm, selectSelectedStatuses } from './tables.selectors';
import { PageSizeMapDto } from 'src/shared/models/config/page-size-map.dto';
import { OrderByMapDto } from 'src/shared/models/config/order-by-map.dto';
import { SortTypeMapDto } from 'src/shared/models/config/sort-type-map.dto';
import { SearchTermMapDto } from '@shared/models/config/search-term-map.dto';
import { StatusEnum } from '@shared/models/enums/status-enum';
import { SelectedStatusesMapDto } from '@shared/models/config/selectedStatusesMapDto';

@Injectable()
export class TablesEffects {
  setPageSize$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setPageSize),
      withLatestFrom(this.store$.select(selectPageSize)),
      concatMap((data) => {
        return of(data).pipe(
          map((data) => setPageSizeSuccess({ data: this.getPageSizeMapFromData(data) })),
          catchError((error) => of(setPageSizeFailure({ error })))
        );
      })
    )
  );

  getPageSize$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPageSize),
      concatMap(({ tableKey }) => {
        return of(tableKey).pipe(
          map((_) => getPageSizeSuccess({ pageSize: Number.parseInt(_) })),
          catchError((error) => of(getPageSizeFailure({ error })))
        );
      })
    )
  );

  setOrderBy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setOrderBy),
      withLatestFrom(this.store$.select(selectOrderBy)),
      concatMap((data) => {
        return of(data).pipe(
          map((data) => setOrderBySuccess({ data: this.getOrderByMapFromData(data) })),
          catchError((error) => of(setOrderByFailure({ error })))
        );
      })
    )
  );

  getOrderBy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getOrderBy),
      concatMap(({ tableKey }) => {
        return of(tableKey).pipe(
          map((_) => getOrderBySuccess({ orderBy: _ })),
          catchError((error) => of(getOrderByFailure({ error })))
        );
      })
    )
  );

  setSortType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setSortType),
      withLatestFrom(this.store$.select(selectSortType)),
      concatMap((data) => {
        return of(data).pipe(
          map((data) => setSortTypeSuccess({ data: this.getSortTypeFromData(data) })),
          catchError((error) => of(setSortTypeFailure({ error })))
        );
      })
    )
  );

  getSortType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSortType),
      concatMap(({ tableKey }) => {
        return of(tableKey).pipe(
          map((_) => getSortTypeSuccess({ sortType: _ })),
          catchError((error) => of(getSortTypeFailure({ error })))
        );
      })
    )
  );


  setSearchTerm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setSearchTerm),
      withLatestFrom(this.store$.select(selectSearchTerm)),
      concatMap((data) => {
        return of(data).pipe(
          map((data) => setSearchTermSuccess({ data: this.getSearchTermMapFromData(data) })),
          catchError((error) => of(setSearchTermFailure({ error })))
        );
      })
    )
  );

  getSearchTerm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSearchTerm),
      concatMap(({ tableKey }) => {
        return of(tableKey).pipe(
          map((_) => getSearchTermSuccess({ searchTerm: _ })),
          catchError((error) => of(getSearchTermFailure({ error })))
        );
      })
    )
  );

  setSelectedStatuses$ = createEffect(() =>
  this.actions$.pipe(
    ofType(setSelectedStatuses),
    withLatestFrom(this.store$.select(selectSelectedStatuses)),
    concatMap((data) => {
      return of(data).pipe(
        map((data) => setSelectedStatusesSuccess({ data: this.getSelectedStatusesFromData(data)})),
        catchError((error) => of(setSelectedStatusesFailure({ error })))
      );
    })
  )
);


getSelectedStatuses$ = createEffect(() =>
this.actions$.pipe(
  ofType(getSelectedStatuses),
  concatMap(({ tableKey }) => {
    return of(tableKey).pipe(
      map((_) => getSelectedStatusesSuccess({ selectedStatuses: [StatusEnum[_]] })),
      catchError((error) => of(getSelectedStatusesFailure({ error })))
    );
  })
)
);


  getPageSizeMapFromData(data: any) {
    const state = Array.isArray(data[1]) ? (data[1] as PageSizeMapDto[]) : [];
    const pageSize = data[0].data;
    const existingValue = state.find((_) => _.key == pageSize.key);
    if (existingValue) {
      existingValue.pageSize = pageSize.pageSize;
    } else {
      const entity: PageSizeMapDto = new PageSizeMapDto(pageSize.key, pageSize.pageSize);
      state.push(entity);
    }
    return state.map((_) => Object.assign({}, _));
  }

  getOrderByMapFromData(data: any) {
    const state = Array.isArray(data[1]) ? (data[1] as OrderByMapDto[]) : [];
    const sort = data[0].data;
    const existingValue = state.find((_) => _.key == sort.key);
    if (existingValue) {
      existingValue.orderBy = sort.orderBy;
    } else {
      const entity: OrderByMapDto = new OrderByMapDto(sort.key, sort.orderBy);
      state.push(entity);
    }
    return state.map((_) => Object.assign({}, _));
  }

  getSortTypeFromData(data: any) {
    const state = Array.isArray(data[1]) ? (data[1] as SortTypeMapDto[]) : [];
    const sortType = data[0].data;
    const existingValue = state.find((_) => _.key == sortType.key);
    if (existingValue) {
      existingValue.desc = sortType.desc;
    } else {
      const entity: SortTypeMapDto = new SortTypeMapDto(sortType.key, sortType.orderBy);
      state.push(entity);
    }
    return state.map((_) => Object.assign({}, _));
  }

  getSearchTermMapFromData(data: any) {
    const state = Array.isArray(data[1]) ? (data[1] as SearchTermMapDto[]) : [];
    const searchTerm = data[0].data;
    const existingValue = state.find((_) => _.key == searchTerm.key);
    if (existingValue) {
      existingValue.searchTerm = searchTerm.searchTerm;
    } else {
      const entity: SearchTermMapDto = new SearchTermMapDto(searchTerm.key, searchTerm.SearchTerm);
      state.push(entity);
    }
    return state.map((_) => Object.assign({}, _));
  }

  getSelectedStatusesFromData(data: any) {
    const state = Array.isArray(data[1]) ? (data[1] as SelectedStatusesMapDto[]) : [];
    const selectedStatuses = data[0].data;
    const existingValue = state.find((_) => _.key == selectedStatuses.key);
    if (existingValue) {
      existingValue.selectedStatuses = selectedStatuses.selectedStatuses;
    } else {
      const entity: SelectedStatusesMapDto = new SelectedStatusesMapDto(selectedStatuses.key, selectedStatuses.selectedStatuses);
      state.push(entity);
    }
    return state.map((_) => Object.assign({}, _));
  }

  constructor(private actions$: Actions, private store$: Store<StoreState>) {}
}
