import { createAction, props } from '@ngrx/store';
import { PageSizeMapDto } from 'src/shared/models/config/page-size-map.dto';
import { OrderByMapDto } from 'src/shared/models/config/order-by-map.dto';
import { SortTypeMapDto } from 'src/shared/models/config/sort-type-map.dto';
import { SearchTermMapDto } from '@shared/models/config/search-term-map.dto';
import { SelectedStatusesMapDto } from '@shared/models/config/selectedStatusesMapDto';
import { StatusEnum } from '@shared/models/enums/status-enum';

export const loadPageSize = createAction('[PageSize] Load PageSize');

export const loadPageSizeSuccess = createAction('[PageSize] Load PageSize Success', props<{ data: number }>());

export const loadPageSizeFailure = createAction('[PageSize] Load PageSize Failure', props<{ error: any }>());

export const setPageSize = createAction('[SetPageSize] Set SetPageSize', props<{ data: PageSizeMapDto }>());
export const setPageSizeSuccess = createAction(
  '[SetPageSize] Set PageSize Success',
  props<{ data: PageSizeMapDto[] }>()
);
export const setPageSizeFailure = createAction('[SetPageSize] Set PageSize Failure', props<{ error: any }>());
export const getPageSize = createAction('[GetPageSize] Set GetPageSize', props<{ tableKey: string }>());
export const getPageSizeSuccess = createAction('[GetPageSize] Get PageSize Success', props<{ pageSize: number }>());
export const getPageSizeFailure = createAction('[GetPageSize] Get PageSize Failure', props<{ error: any }>());

export const setOrderBy = createAction('[SetOrderBy] Set SetOrderBy', props<{ data: OrderByMapDto }>());
export const setOrderBySuccess = createAction('[SetOrderBy] Set OrderBy Success', props<{ data: OrderByMapDto[] }>());
export const setOrderByFailure = createAction('[SetOrderBy] Set OrderBy Failure', props<{ error: any }>());

export const getOrderBy = createAction('[GetOrderBy] Set OrderBy', props<{ tableKey: string }>());
export const getOrderBySuccess = createAction('[GetOrderBy] Get OrderBy Success', props<{ orderBy: string }>());
export const getOrderByFailure = createAction('[GetOrderBy] Get OrderBy Failure', props<{ error: any }>());

export const setSortType = createAction('[SetSortType] Set SetSortType', props<{ data: SortTypeMapDto }>());
export const setSortTypeSuccess = createAction(
  '[SetSortType] Set SortType Success',
  props<{ data: SortTypeMapDto[] }>()
);
export const setSortTypeFailure = createAction('[SetSortType] Set SortType Failure', props<{ error: any }>());

export const getSortType = createAction('[GetSortType] Set SortType', props<{ tableKey: string }>());
export const getSortTypeSuccess = createAction('[GetSortType] Get SortType Success', props<{ sortType: string }>());
export const getSortTypeFailure = createAction('[GetSortType] Get SortType Failure', props<{ error: any }>());

export const setSearchTerm = createAction('[SetSearchTerm] Set SetSearchTerm', props<{ data: SearchTermMapDto }>());
export const setSearchTermSuccess = createAction('[SetSearchTerm] Set SearchTerm Success', props<{ data: SearchTermMapDto[] }>());
export const setSearchTermFailure = createAction('[SetSearchTerm] Set SearchTerm Failure', props<{ error: any }>());

export const getSearchTerm = createAction('[GetSearchTerm] Set SearchTerm', props<{ tableKey: string }>());
export const getSearchTermSuccess = createAction('[GetSearchTerm] Get SearchTerm Success', props<{ searchTerm: string }>());
export const getSearchTermFailure = createAction('[GetSearchTerm] Get SearchTerm Failure', props<{ error: any }>());

export const setSelectedStatuses = createAction('[SetSelectedStatuses] Set SetSelectedStatuses', props<{ data: SelectedStatusesMapDto }>());
export const setSelectedStatusesSuccess = createAction('[SetSelectedStatuses] Set SelectedStatuses Success', props<{ data: SelectedStatusesMapDto[] }>());
export const setSelectedStatusesFailure = createAction('[SetSelectedStatuses] Set SelectedStatuses Failure', props<{ error: any }>());

export const getSelectedStatuses = createAction('[GetSelectedStatuses] Set SelectedStatuses', props<{ tableKey: string }>());
export const getSelectedStatusesSuccess = createAction('[GetSelectedStatuses] Get SelectedStatuses Success', props<{ selectedStatuses: StatusEnum[] }>());
export const getSelectedStatusesFailure = createAction('[GetSelectedStatuses] Get SelectedStatuses Failure', props<{ error: any }>());