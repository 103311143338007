import { Component, DoCheck, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AuthGuard } from '../../guards/auth.guard';
import { AppPermission } from '../../models/enums/app-permission.enum';

@Component({
  selector: 'app-edit-actions',
  templateUrl: './edit-actions.component.html',
  styleUrls: ['./edit-actions.component.scss'],
})
export class EditActionsComponent implements OnInit, OnDestroy, DoCheck {
  @Input() form: FormGroup;
  @Input() loading: boolean;
  @Input() allowedRoles: AppPermission[];
  @Output() clear = new EventEmitter();
  @Output() save = new EventEmitter();
  formStatus = 'DISABLED';
  editAllowed = true;

  constructor(private authGuard: AuthGuard) {}

  ngOnDestroy(): void {}

  ngOnInit() {
    if (this.allowedRoles != null) {
      // this.editAllowed = this.roleGuard.isInRoleActivate(this.allowedRoles);
      this.editAllowed = this.authGuard.hasClaims(this.allowedRoles);
    }
  }

  ngDoCheck() {
    this.formStatus = this.form.status;
  }

  toggleDisabled() {
    console.log(this.form);
    if (!this.editAllowed) {
      return;
    }
    if (this.form.disabled) {
      this.form.enable({ emitEvent: false });
    } else {
      this.form.disable({ emitEvent: false });
    }
  }

  onClear() {
    this.clear.emit();
  }

  onSave() {
    if (!this.editAllowed) {
      return;
    }
    if (this.form.valid) {
      this.save.emit();
    }
  }
}
