import { EventEmitter, Injectable, Output } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NavService {
  public currentUrl = new BehaviorSubject<string>(undefined);
  @Output() change: EventEmitter<boolean> = new EventEmitter();
  @Output() refresh: EventEmitter<null> = new EventEmitter();

  constructor(private router: Router) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl.next(event.urlAfterRedirects);
      }
    });
  }

  retractAllTabs() {
    this.change.emit();
  }

  refreshAllTabs() {
    this.refresh.emit();
  }
}
