import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import {
  loadPageableReports,
  loadPageableReportsSuccess,
  loadPageableReportsFailure,
  deleteReport,
  deleteReportFailure,
  deleteReportSuccess,
  addReport,
  addReportSuccess,
  addReportFailure,
  updateReport,
  updateReportSuccess,
  updateReportFailure,
  sendAttachment,
  sendAttachmentSuccess,
  sendAttachmentFailure,
  loadPdf,
  loadPdfFailure,
  loadPdfSuccess,
  loadReportHistory,
  loadReportHistoryFailure,
  loadReportHistorySuccess,
  addReportAtachmentProgress,
  addReportAtachmentsFailure,
  addReportAtachmentsScheduled,
  addReportAtachments,
  loadReport,
  loadReportSuccess,
  loadReportFailure,
} from './reports.actions';
import { concatMap, map, catchError, tap, finalize, takeUntil } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { ReportApiCallerService } from '@shared/api-services/report-api-caller.service';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { StoreState } from '@store/store-state';
import { GetPageableQuery } from '@shared/models/queries/get-pageable.query';
import { PageableQuery } from '@shared/models/contracts/pageable-query';
import { AttachmentProgressDto } from '@shared/models/attachment-progress.dto';
import { HttpEventType } from '@angular/common/http';
import { UploadStatus } from '@shared/models/upload-status';
import { SnackBarWrapperService } from '@shared/services/snack-bar-wrapper.service';
import { Guid } from 'guid-typescript';

@Injectable()
export class ReportEffects {
  loadpageableReports$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPageableReports),
      concatMap(({ filters }) => {
        if(filters){
          this.filters =filters
        }
        return this.ReportApiCaller.getPageableReports(filters).pipe(
          map((_) => loadPageableReportsSuccess({ data: _ })),
          catchError((error) => of(loadPageableReportsFailure({ error })))
        );
      })
    )
  );

  deleteReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteReport),
      concatMap(({ id }) => {
        return this.ReportApiCaller.deleteReport(id).pipe(
          catchError((error) => {
            this.reloadReports();
            return of(deleteReportFailure({ error }));
          }),
          map((_) => deleteReportSuccess({ id: id })),
          tap(() => this.reloadReports())
        );
      })
    )
  );

  addReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addReport),
      concatMap(({ data,attachments }) => {
        return this.ReportApiCaller.addReport(data).pipe(
          map((_) => addReportSuccess({ id: data.id })),
          tap((_) =>{ this.reloadReports();
          if(attachments!=null)
               this.store$.dispatch(addReportAtachments({data: attachments,reportId: data.id}))
}),
          catchError((error) => {
            return of(addReportFailure({ error }));
          })
        );
      })
    )
  );

  loadReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadReport),
      concatMap(({ id }) => {
        return this.ReportApiCaller.getReport(id).pipe(
          map((_) => {
            return loadReportSuccess({ data: _ });
          }),
          catchError((error) => of(loadReportFailure({ error })))
        );
      })
    )
  );

  updateReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateReport),
      concatMap(({ data }) => {
        return this.ReportApiCaller.updateReport(data).pipe(
          map((_) => updateReportSuccess({ id: data.id })),
          tap((_) => this.reloadReports()),
          catchError((error) => of(updateReportFailure({ error })))
        );
      })
    )
  );

  sendAttachment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendAttachment),
      concatMap(({ data }) => {
        return this.ReportApiCaller.sendAttachment(data).pipe(
          tap((_) => {
            this.reloadReports();
          }),
          map((_) => sendAttachmentSuccess({})),
          tap(() => {
            this.reloadReports();
          }),
          catchError((error) => {
            tap(() => {});
            return of(sendAttachmentFailure({ error }));
          })
        );
      })
    )
  );



  loadPdf$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPdf),
      concatMap(({ ReportId }) => {
        return this.ReportApiCaller.getPdf(ReportId).pipe(
          map((_) => 
             loadPdfSuccess({ data: _ })
          ),
          catchError((error) => of(loadPdfFailure({ error })))
        );
      })
    )
  );


  loadReportHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadReportHistory),
      concatMap(({ reportId }) => {
        return this.ReportApiCaller.getReportHistory(reportId).pipe(
          map((_) => loadReportHistorySuccess({ data: _ })),
          catchError((error) => of(loadReportHistoryFailure({ error })))
        );
      })
    )
  );


  addReportAtachments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addReportAtachments),
      concatMap(({ data, reportId }) => {
        data.forEach((element) => {
          const id = Guid.create().toString();

          this.ReportApiCaller.addAttachment(id, reportId, element).subscribe(
            (event) => {
              const progressInfo = new AttachmentProgressDto(id, element.name);
              if (event.type === HttpEventType.UploadProgress) {
                progressInfo.progress = Math.round((100 * event.loaded) / event.total);
                progressInfo.status = UploadStatus.InProgress;
              } else if (event.type === HttpEventType.Response) {
                progressInfo.status = UploadStatus.Finished;
                progressInfo.progress = 100;
              }
              this.store$.dispatch(addReportAtachmentProgress({ info: progressInfo, reportId }));
            },
            (error) => {
              this.store$.dispatch(addReportAtachmentsFailure({ error }));
              this.matSnackBar.openMessage('Errors.AfterCreatingOrderWhileSendingFile', 'error');
             // this.navigateToReport(reportId);
            }
          );
        });
        return of(addReportAtachmentsScheduled());
      })
    )
  );

 private reloadReports() {
    this.store$.dispatch(loadPageableReports({ filters: this.filters }));
  }
  private filters: GetPageableQuery;


  constructor(
    private actions$: Actions,
    private ReportApiCaller: ReportApiCallerService,
    private router: Router,
    private matSnackBar: SnackBarWrapperService,
    private store$: Store<StoreState> //private matSnackBar: SnackBarWrapperService
  ) {}
}
