<a
  mat-list-item
  (click)="onItemSelected()"
  [routerLinkActive]="item.route ? 'active' : ''"
  [routerLink]="item.route"
  [routerLinkActiveOptions]="{ exact: true }"
  [ngClass]="{ 'child-active': hasActiveChild(), expanded: expanded }"
  class="menu-list-item"
>
  <mat-icon class="routeIcon">{{ item.iconName }}</mat-icon>
  <span class="navigation-item-label">{{ item.displayName | translate }}</span>
  <span class="navigation-item-expand-icon" fxFlex *ngIf="item.children && item.children.length">
    <span fxFlex></span>
    <mat-icon [@indicatorRotate]="expanded ? 'expanded' : 'collapsed'">
      expand_more
    </mat-icon>
  </span>
</a>
<div class="pl-10" *ngIf="expanded">
  <app-menu-list-item
    class="isParentExpanded"
    *ngFor="let child of item.children"
    [item]="child"
    [depth]="depth + 1"
    #child
  >
  </app-menu-list-item>
</div>

<ng-template #label>
  <mat-icon class="routeIcon">{{ item.iconName }}</mat-icon>
  <span class="navigation-item-label">{{ item.displayName | translate }}</span>
  <span class="navigation-item-expand-icon" fxFlex *ngIf="item.children && item.children.length">
    <span fxFlex></span>
    <mat-icon [@indicatorRotate]="expanded ? 'expanded' : 'collapsed'">
      expand_more
    </mat-icon>
  </span>
</ng-template>
