import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { ValidatorPatterns } from '@shared/validators/validator-patterns';
import { AddFactureItem } from './facture-item.form';

export class CommentForm extends FormGroup {
  constructor() {
    super({
      comments: new FormControl(undefined,Validators.required),
    });
  }
}
