import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { ValidatorPatterns } from '@shared/validators/validator-patterns';

export class ReportForm extends FormGroup {
  constructor() {
    super({
      id: new FormControl(undefined),
      moduleId: new FormControl(undefined, [Validators.required]),
      subject: new FormControl(undefined, [Validators.required]),
      description: new FormControl(undefined, [Validators.required]),
      type: new FormControl(undefined, [Validators.required]),
      status: new FormControl(undefined, [Validators.required]),
      priority: new FormControl(undefined, [Validators.required]),
      comments: new FormArray([]),
      redmine: new FormControl(undefined),
      attachments: new FormControl(null),
      module: new FormControl(undefined),
      moduleName: new FormControl(undefined)
    });
  }
}
