import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { IdentityApiCallerService } from '../../shared/api-services/identity-api-caller.service';
import {
  loadAllClaims,
  loadAllClaimsFailure,
  loadAllClaimsSuccess,
  storeUserData,
  storeUserDataFailure,
  storeUserDataSuccess,
} from './user-data.actions';
import { UserDataDto } from '../../shared/models/user-data.dto';
// import { ApiUsersApiService } from '../../shared/api-services/api-services';

@Injectable()
export class UserDataEffects {
  userData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(storeUserData),
      concatMap(({ data }) => {
        return this.userData(data).pipe(
          map((_) => storeUserDataSuccess({ data: new UserDataDto(_) })),
          catchError((error) => of(storeUserDataFailure({ error })))
        );
      })
    )
  );

  allClaims$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAllClaims),
      concatMap(() => {
        return this.client.getSystemPermissions().pipe(
          map((_) => loadAllClaimsSuccess({ data: _ })),
          catchError((error) => of(loadAllClaimsFailure({ error })))
        );
      })
    )
  );

  private userData(data: UserDataDto) {
    return of(data);
  }

  constructor(private actions$: Actions, private client: IdentityApiCallerService) {}
}
