import {
  FlexibleConnectedPositionStrategyOrigin,
  Overlay,
  OverlayConfig,
  OverlayRef,
  PositionStrategy,
} from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { LoaderSpinnerComponent } from '../widgets/loader-spinner/loader-spinner.component';
import { getConnectionPositionsPair, OverlayPosition } from './overlay-positions';

@Injectable({
  providedIn: 'root',
})
export class OverlayWrapperService {
  private overlayRefs = new Map<string, OverlayRef>();

  constructor(private overlay: Overlay) {}

  public closeOverlay(key: string) {
    const overlayRef = this.overlayRefs.get(key);
    if (overlayRef != null) {
      overlayRef.detach();
      overlayRef.dispose();
      this.overlayRefs.delete(key);
    }
  }

  public openLoader(key: string, dom: FlexibleConnectedPositionStrategyOrigin, diameter = 240) {
    const component = this.openAttachedOverlay(key, LoaderSpinnerComponent, dom, 'top').componentRef;
    if (component != null && component.instance != null) {
      component.instance.diameter = diameter;
    }

    return component;
  }

  public openAttachedOverlay<T>(
    key: string,
    component: ComponentType<T> | ComponentPortal<T>,
    dom: FlexibleConnectedPositionStrategyOrigin,
    position: OverlayPosition,
    closeOnBackdrop?: boolean
  ) {
    let overlayRef = this.overlayRefs.get(key);
    if (overlayRef != null) {
      return null;
    }
    if (position == null) {
      position = 'right';
    }

    overlayRef = this.overlay.create(this.getOverlayConfig(dom, position, { hasBackdrop: closeOnBackdrop }));
    const componentRef = overlayRef.attach(
      component instanceof ComponentPortal ? component : new ComponentPortal(component)
    );
    this.overlayRefs.set(key, overlayRef);

    if (closeOnBackdrop) {
      overlayRef.backdropClick().subscribe(() => {
        this.closeOverlay(key);
      });
    }

    return { componentRef, overlayRef };
  }

  private getOverlayConfig(
    dom: FlexibleConnectedPositionStrategyOrigin,
    position,
    config?: OverlayConfig
  ): OverlayConfig {
    config = new OverlayConfig({
      positionStrategy: this.getOverlayPosition(dom, position),
      ...config,
    });

    return config;
  }

  public getOverlayPosition(dom: FlexibleConnectedPositionStrategyOrigin, position): PositionStrategy {
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(dom)
      .withPositions([getConnectionPositionsPair(position)])
      .withPush(false);

    return positionStrategy;
  }
}
