export class UserDataDto {
  enumPermission: number[];
  email: string;
  sub: string;
  dataKey?: string;

  constructor(data?) {
    if (data) {
      this.enumPermission = this.getPermissions(data.EnumPermission ? data.EnumPermission : data.enumPermission);
      this.email = data.email;
      this.sub = data.sub;
      this.dataKey = data.DataKey ? data.DataKey : data.dataKey;
    }
  }

  private getPermissions(data: string[] | string) {
    if (Array.isArray(data)) {
      const result = data.map((_) => Number.parseInt(_, 10));
      return result;
    } else if (typeof data === 'string') {
      return [Number.parseInt(data, 10)];
    }
    return [];
  }
}
