<app-base-dialog [showSaveButton]="checkIfChanged()" [title]="['Report.Edit' | translate]+data.report?.reportNumber" [form]="form" (cancel)="close(false)" (confirm)="save()">
  <div cdkFocusRegionStart class="container">
    <div class="row">
      <div class="col-6">
        <div class="row">
        <div class="col-sm-3 display-grid">
          <!-- <app-autocomplete 
          [label]="getLabel()" [searchLabel]="'Common.Search' | translate"
          [placeholder]="'Module.Module' | translate" [form]="form" [labelField]="'moduleName'" [idField]="'moduleId'"
          [filterFunc]="filter" [data]="filteredModule$ | async" [required]='true'>
        </app-autocomplete> -->
        <app-autocomplete-show-all
        [selectedItem]="data.report?.module"
                      [label]="getLabel()"
                      [searchLabel]="'Common.Search' | translate"
                      [placeholder]="'Module.Module' | translate"
                      [form]="form"
                      [data]="filteredModule$| async"
                      [idField] = "'moduleId'"
                      [labelField] = "'moduleName'"
                      [filterFunc]="filter"
                      >
                    </app-autocomplete-show-all>      
      </div>
        <div class="col-6">
          </div>
        </div>
<div class="row">
  <div class="col-12">
    <app-input [readonly]="true" type="text" [width]="430"  id="subject" [label]="'Report.Subject' | translate" [form]="form.get('subject')">
    </app-input></div>
</div>
        <div class="row">
          <div class=col-12>
        <mat-form-field style="width: 90%;" >
            <mat-label>{{ 'Common.Description' | translate }}</mat-label>
            <textarea readonly  id="description" style="min-height: 50px;" matInput
              [formControl]="form.get('description')"></textarea>
              <mat-error *ngIf="form.get('description').hasError('required')">
                {{ 'ValidationErrors.FieldRequired' | translate }}
              </mat-error>
          </mat-form-field>
        </div>
        </div>
        <div class="row">
          <div class="col-6">
      <mat-form-field >
        <mat-label>{{'Report.Priority' | translate}}</mat-label>
        <mat-select [formControl]="form.get('priority')" [disabled]="!checkIsInRole(appPermission.ReportsEdit)" >
          <mat-option *ngFor="let priority of data.priorities; let i =index" [value]="i">
            {{'Priority.'+priority | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field></div>
      <div class="col-6">
      <mat-form-field >
        <mat-label>{{'Report.Type' | translate}}</mat-label>
        <mat-select [formControl]="form.get('type')" [disabled]="!checkIsInRole(appPermission.ReportsEdit)">
          <mat-option *ngFor="let type of data.types; let i =index" [value]="i">
            {{'Type.'+type | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field></div>
      <div class="col-6">
      <mat-form-field>
        <mat-label>{{'Report.Status' | translate}}</mat-label>
        <mat-select [formControl]="form.get('status')" [disabled]="!checkIsInRole(appPermission.ReportsEdit)">
          <mat-option *ngFor="let status of data.statuses; let i =index" [value]="i">
            {{'Status.'+status | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      </div>
      <div class="col-6">
        <app-input type="number" [readonly]="!checkIsInRole(appPermission.ReportsEdit)" 
        id="subject" [label]="'Report.Redmine' | translate" [form]="form.get('redmine')">
        </app-input></div></div>
    
    <div class="row">
      <div class="col-12">
        <span *ngIf="data.report?.attachments.length>0"><br />{{'Report.Attachments' | translate}}</span>
        <div *ngFor="let attachment of data.report?.attachments">
          {{attachment.fileName}}<button   mat-button matSuffix mat-icon-button aria-label="Clear"
           (click)="downloadAttachment(attachment)">
           <mat-icon>download</mat-icon>
         </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <span *ngIf="addAttachmentsMode==false&&this.form.get('attachments').value==null" (click)="changeAttachmentsMode()">
          {{ 'Report.AddAttachments' | translate }}
          <button   mat-button matSuffix mat-icon-button aria-label="Clear">
            <mat-icon>add_circle</mat-icon>
          </button></span>
          <span *ngIf="addAttachmentsMode==true||this.form.get('attachments').value!=null">
            {{ 'Report.DeleteAttachments' | translate }}
            <button  mat-button matSuffix mat-icon-button aria-label="Clear" (click)="removeAttachments()">
              <mat-icon>add_circle</mat-icon>
            </button></span>
            <app-files-upload *ngIf="addAttachmentsMode==true" [form]="attachementsForm"></app-files-upload>
               <br />
               <!-- <mat-error style="max-width: 600px" *ngIf="addAttachmentsMode==true && form.dirty==false">
                 {{ 'ValidationErrors.AddingAttachment' | translate }}
               </mat-error> -->
      </div>
    </div>

    <!-- <div class="row">
      <div class="col-12">

      </div>
    </div> -->
          
      </div>

<div class="col-6">

  <div class="row">
    <div class="col-12">

  <span *ngIf="addCommentMode==false">
    {{ 'Report.AddComment' | translate }}
    <button   mat-button matSuffix mat-icon-button aria-label="Clear" (click)="addItem()">
      <mat-icon>add_circle</mat-icon>
    </button></span>
    <span *ngIf="addCommentMode==true">
      {{ 'Report.DeleteComment' | translate }}
      <button  mat-button matSuffix mat-icon-button aria-label="Clear" (click)="remove()">
        <mat-icon>add_circle</mat-icon>
      </button></span>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div *ngFor="let item of valuesForm.controls; let i = index;">
          <div>
              <mat-form-field style="width: 100%;" >
          <mat-label *ngIf="data.report?.comments[i]?.username!=null">
            <b>{{data.report?.comments[i]?.username + '    ' }}</b>{{'Report.Wroten' | translate}}</mat-label>
            <mat-label *ngIf="data.report?.comments[i]?.username==null">
              {{'Report.Comment' | translate}}</mat-label>
          <textarea [readonly]="isReadonly(i)"  style="min-height: 50px;" matInput
            [formControl]="item.get('comments')"></textarea>
            <mat-error *ngIf="item.get('comments').hasError('required')">
              {{ 'ValidationErrors.CommentMustBeFilled' | translate }}
            </mat-error>
        </mat-form-field>
      </div>
          </div>
        </div>
</div>

      </div>


    </div>
  </div>
</app-base-dialog>
