import { StoreState } from '../store-state';
import { createSelector } from '@ngrx/store';
import { State } from './tables.reducer';

const selectFeature = (state: StoreState) => {
  return state.tableSettings;
};
export const selectPageSize = createSelector(selectFeature, (state: State) => {
  return state.pageSize;
});

export const selectOrderBy = createSelector(selectFeature, (state: State) => {
  return state.sort;
});

export const selectSearchTerm = createSelector(selectFeature, (state: State) => {
  return state.searchTerm;
});
export const selectSortType = createSelector(selectFeature, (state: State) => {
  return state.sortType;
});

export const selectSelectedStatuses= createSelector(selectFeature, (state: State) => {
  return state.selectedStatuses;
});