import { ConnectionPositionPair } from '@angular/cdk/overlay';

export declare type OverlayPosition = 'left' | 'bottom' | 'right' | 'top' | 'center';

export function getConnectionPositionsPair(position: OverlayPosition): ConnectionPositionPair {
  switch (position) {
    case 'left':
      return leftPosition;
    case 'bottom':
      return bottomPosition;
    case 'right':
      return rightPosition;
    case 'center':
      return centerPosition;
    case 'top':
      return topPosition;
  }
}

const rightPosition: ConnectionPositionPair = {
  originX: 'end',
  originY: 'center',
  overlayX: 'end',
  overlayY: 'bottom',
};

const leftPosition: ConnectionPositionPair = {
  originX: 'start',
  originY: 'center',
  overlayX: 'end',
  overlayY: 'bottom',
};

const bottomPosition: ConnectionPositionPair = {
  originX: 'center',
  originY: 'bottom',
  overlayX: 'end',
  overlayY: 'top',
};

const topPosition: ConnectionPositionPair = {
  originX: 'center',
  originY: 'top',
  overlayX: 'start',
  overlayY: 'top',
};

const centerPosition: ConnectionPositionPair = {
  originX: 'center',
  originY: 'center',
  overlayX: 'center',
  overlayY: 'center',
};
