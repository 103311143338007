import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/shared/guards/auth.guard';
import { AuthorisedLayoutComponent } from './layout/authorised-layout/authorised-layout.component';
import { AutoLoginComponent } from './layout/auto-login/auto-login.component';
import { UnauthorisedComponent } from './layout/unauthorised/unauthorised.component';

const routes: Routes = [
  {
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
    path: 'authorised',
    component: AuthorisedLayoutComponent,
    children: [
      {
        canActivate: [AuthGuard],
        path: 'reports',
        loadChildren: () => import('./report/reports.module').then((m) => m.ReportsModule),
      },
      {
        canActivate: [AuthGuard],
        path: 'modules',
        loadChildren: () => import('./module/module.module').then((m) => m.ModuleModule),
      },
      

      { path: '**', redirectTo: 'reports' },
    ],
  },
  { path: 'unauthorized', component: UnauthorisedComponent },
  { path: 'autologin', component: AutoLoginComponent },
  { path: '**', redirectTo: 'authorised' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
