<div class="scroll">
<div>
<span style="margin-left: 40%"><b>{{'Report.History' | translate}} {{reportHistory?.updateInfoModels[0]?.reportNumber}}</b></span>
<button id="cancel" style="float: right;" mat-button matSuffix mat-icon-button aria-label="Clear"
(click)="close(false)">
<mat-icon>close</mat-icon>
</button></div>
    <div cdkFocusRegionStart class="container-fluid">
        <ng-container *ngIf="reportHistory != null && reportHistory.updateInfoModels != null">
          <div *ngFor="let history of reportHistory.updateInfoModels">
            <br *ngIf="history.updatedValues.length>0" />
            <mat-accordion *ngIf="history.updatedValues.length>0">
              <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                  <mat-panel-title style="width: 90%;">
                    <div class="container">
                    <div class="row">
                        <span class="col-4">
                      <span class="color action">{{ 'Common.Action' | translate }}:</span>
                      <span class="  color">
                        {{ 'Report.' + history.commandName | translate }}
                      </span></span>     
                    <span class="col-4">
                      <span class="color action">{{ 'Common.ActionDate' | translate }}:</span>
                      <span class="color">
                        {{ history.createDate | date: 'short' }}
                      </span>
                    </span>
                              <span class="col-4">
                      <span class="color action">{{ 'UpdatedValues.ModifiedBy' | translate }}</span>
                      <span class="col-4  color">
                         {{history.userId}}
                      </span></span>
                    </div></div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-card>
                  <mat-list>
                    <div *ngFor="let h of history.updatedValues">
                      <mat-list-item>
                        <br /><br />
                        <div class="value">
                          <b>Nazwa zmienionego pola:</b> {{ 'UpdatedValues.' + h.propertyName | translate }}
                        </div>
                        <div class="value">
                          <b>Poprzednia wartość:</b>
                          <span *ngIf="translateValue(h.propertyName)==false;else translateEnum">
                            {{ h.oldValue }}</span>
                          <ng-template #translateEnum>
                              {{h.propertyName+ '.'+ h.oldValue |translate}}
                          </ng-template>
                        </div>
                        <div class="icon color">
                          <mat-icon>arrow_right_alt</mat-icon>
                        </div>
                        <div class="value">
                          <b>Wartość po zmianie:</b>
                          <span *ngIf="translateValue(h.propertyName)==false;else translateEnumVal">&nbsp;{{ h.newValue }}</span>
                          <ng-template #translateEnumVal>
                              &nbsp;{{h.propertyName+'.'+ h.newValue | translate}}
                          </ng-template>
                        </div>
                        <br />
                      </mat-list-item>
                      <mat-divider></mat-divider>
                      <br />
                    </div>
                  </mat-list> </mat-card
                ><br />
              </mat-expansion-panel>
            </mat-accordion>
          </div>
          <br />
        </ng-container>
      </div>
    </div>