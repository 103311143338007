import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiCaller } from './base-api-caller';

@Injectable({
  providedIn: 'root',
})
export class IdentityApiCallerService extends BaseApiCaller {
  protected controllerPath = 'ApiUsers';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public emailUnique(email: string) {
    return this.httpClient.get<boolean>(this.getIdentityFullPath('email-unique?email=' + email));
  }

  public getSystemPermissions() {
    return this.httpClient.get<boolean>(this.getIdentityFullPath('GetPermissionsDictionary'));
  }
}
