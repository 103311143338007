import { Injectable } from '@angular/core';
import { BaseApiCaller, HttpOptions } from './base-api-caller';
import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { GetPageableQuery } from '@shared/models/queries/get-pageable.query';
import { PageableDto } from '@shared/models/contracts/pageable.dto';
import { environment } from '@env/environment';
import { map, tap } from 'rxjs/operators';
import { ReportDto } from '@shared/models/report.dto';
import { ReportHistoryDto } from '@shared/models/report-history.dto';
import { GetPageableReportsQuery } from '@shared/models/queries/get-pageable-reports.query';

@Injectable({
  providedIn: 'root',
})
export class ReportApiCallerService extends BaseApiCaller {
  protected controllerPath = 'reports';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getPageableReports(filter: GetPageableReportsQuery) {
    const tmpparams = this.prepareParams(filter);
    const params =this.modifyFormat(tmpparams,filter.statuses);
    return this.get<PageableDto<ReportDto>>('', { params });
  }

  updateReport(data: ReportDto) {
    return this.put('', data);
  }

  addReport(data: ReportDto) {
    return this.post('', data);
  }

  sendAttachment(data: FormData) {
    return this.post('loadAttachment', data);
  }

  getReport(id: string) {
    return this.get<ReportDto>(id);
  }

  public deleteReport(id: string) {
    return this.delete(id);
  }

  public getReportHistory(id: string) {
    return this.get<ReportHistoryDto>('history/'+id);
  }


  getPdf(ReportId: string) {
    var tmp =environment.apiRoot + '/api/' +'Report'+ '/pdf/'+ReportId;
    return this.httpClient.get(environment.apiRoot + '/api/' +'Report'+ `/pdf/${ReportId}`,{responseType: 'blob'})
    .pipe(
      map((_) => _)
    );;

  }


  public addAttachment(id: string, reportId: string, file: File) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('reportId', reportId);
    formData.append('id', id);

    const uploadReq = new HttpRequest('POST', this.getFullPath('attachment'), formData, {
      reportProgress: true,
    });

    return this.httpClient.request(uploadReq);
  }

  public getAttachment(id: string) {
    var path = this.getFullPath('attachment/'+id);
    return this.httpClient.get(path, { responseType: 'blob', observe: 'response' }).pipe(
      map((_) => _)
    );
  }

  private modifyFormat(params: HttpParams, statuses: number[]): HttpParams {
    params = params.delete('statuses');
    if (statuses != null) {
      statuses.forEach((number) => {
        params = params.append('statuses', number.toString());
      });
    }
    return params;
  }

}
