import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { ModuleApiCallerService } from '@shared/api-services/module-api-caller.service';
import { AuthGuard } from '@shared/guards/auth.guard';
import { ReportHistoryDto } from '@shared/models/report-history.dto';
import { ReportDto } from '@shared/models/Report.dto';
import { loadReportHistory } from '@store/factures/reports.actions';
import { selectReportHistory } from '@store/factures/reports.selectors';
import { StoreState } from '@store/store-state';
import { filter } from 'rxjs/internal/operators/filter';
import { AddReportComponent } from '../add-report/add-report.component';


@Component({
  selector: 'app-report-history',
  templateUrl: './report-history.component.html',
  styleUrls: ['./report-history.component.scss']
})
@UntilDestroy()
export class ReportHistoryComponent implements OnInit {

  reportHistory: ReportHistoryDto;
  panelOpenState:boolean;

  constructor(
    private store$: Store<StoreState>,
    private dialogRef: MatDialogRef<AddReportComponent>,
    private dialog: MatDialog,
    private authGuard: AuthGuard,
    @Inject(MAT_DIALOG_DATA) public data: {  report:ReportDto,types: string[]; priorities: string[], statuses: string[],reportId:string },
    private moduleApiCaller: ModuleApiCallerService,
  ) { }

  ngOnInit(): void {
    this.store$
    .pipe(
      select(selectReportHistory),
      filter((_) => _ != null),
      untilDestroyed(this)
    )
    .subscribe((data) => {
      this.reportHistory = data;
    });
    this.store$.dispatch(loadReportHistory({reportId: this.data?.report?.id!=null||undefined?this.data.report.id:this.data.reportId}));

  }

  close(mode){
    this.dialogRef.close(mode);
  }

  translateValue(propertyName:string):boolean{
    var result = false;
    var property = propertyName.toLowerCase();
    if(property=='status'||property=='type'||property=='priority')
    result = true;
    return result;
  }
}
