import { Pipe, PipeTransform } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

@Pipe({
  name: 'errorResponsePipe',
})
export class ErrorResponsePipe implements PipeTransform {
  transform(err: HttpErrorResponse): any {
    const errorResponse = JSON.parse(err['response'])['errors'];
    if (errorResponse['Name']) {
      return errorResponse['Name'][0];
    }
    if (errorResponse['ContractType']) {
      return errorResponse['ContractType'][0];
    }
    return 'Wystąpił błąd';
  }
}
