<div class="ml-20">
  <mat-form-field class="width-80">
    <mat-label>Dni</mat-label>
    <input matInput type="number" [(ngModel)]="range.days" (ngModelChange)="valueChanged()" />
  </mat-form-field>

  <mat-form-field class="width-80">
    <mat-label>Godziny</mat-label>
    <input matInput type="number" [(ngModel)]="range.hours" (ngModelChange)="valueChanged()" />
  </mat-form-field>

  <mat-form-field class="width-80">
    <mat-label>Minuty</mat-label>
    <input matInput type="number" [(ngModel)]="range.minutes" (ngModelChange)="valueChanged()" />
  </mat-form-field>
</div>
