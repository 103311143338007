import { NotificationDto } from '../../shared/models/notification.dto';
import {
  loadNotificationsSuccess,
  loadNotificationsFailure,
  loadNotifications,
  receivedNotifications,
  receivedNotificationsSuccess,
  receivedNotificationsFailure,
  markNotificationsAsReceivedSuccess,
} from './notification.action';
import { createReducer, Action, on } from '@ngrx/store';

export const notificationsFeatureKey = 'notifications';

export interface State {
  notifications?: NotificationDto[];
}
export const initialState: State = {};

const notificationsReducer = createReducer(
  initialState,

  on(loadNotifications, (state) => state),
  on(loadNotificationsSuccess, (state, { data }) => setNotifications(state, data)),
  on(loadNotificationsFailure, (state) => setNotifications(state, null)),

  on(receivedNotifications, (state) => state),
  on(receivedNotificationsSuccess, (state, { data }) => addNotifications(state, data)),
  on(receivedNotificationsFailure, (state) => addNotifications(state, null)),

  on(markNotificationsAsReceivedSuccess, (state, { ids }) => markAsReceived(state, ids))
);

function setNotifications(state, notifications: NotificationDto[]) {
  return { ...state, notifications };
}

function addNotifications(state: State, notifications: NotificationDto[]) {
  let data = state.notifications;
  if (Array.isArray(data)) {
    data = data.concat(notifications);
  } else {
    data = notifications;
  }

  return { ...state, notifications: data };
}

function markAsReceived(state: State, ids: string[]) {
  let data = state.notifications;
  if (Array.isArray(data)) {
    data
      .filter((_) => ids.includes(_.id))
      .forEach((item) => {
        item.received = true;
      });
  } else {
    data = [];
  }
  data = data.map((_) => _);

  return { ...state, notifications: data };
}

export function reducer(state: State | undefined, action: Action) {
  return notificationsReducer(state, action);
}
