import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageableDto } from '@shared/models/contracts/pageable.dto';
import { SearchModel } from '@shared/models/contracts/search-model';
import { SelectModel } from '@shared/models/contracts/select-model';
import { DictionaryModel } from '@shared/models/dictionary-model';
import { ModuleDto } from '@shared/models/module.dto';
import { GetPageableQuery } from '@shared/models/queries/get-pageable.query';
import { ValidationResultDto } from '@shared/models/validation/validation-result.dto';
import { BaseApiCaller } from './base-api-caller';

@Injectable({
  providedIn: 'root',
})
export class ModuleApiCallerService extends BaseApiCaller {
  protected controllerPath = 'module';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getClientsSearchModel(filter: SearchModel) {
    return this.get<SelectModel<string>[]>('search-model', { params:  this.prepareParams(filter)  });
  }

  addModule(data: ModuleDto) {
    return this.post('', data);
  }

  public deleteModule(id: string) {
    return this.delete(id);
  }

  getPageableModules(filter: GetPageableQuery) {
    const params = this.prepareParams(filter);

    return this.get<PageableDto<ModuleDto>>('', { params });
  }

  public moduleNameUnique(moduleName: string) {
    return this.httpClient.get<ValidationResultDto>(this.getFullPath('unique/' + moduleName));
  }

  public getModules() {
    return this.get<SelectModel<string>[]>('allModules');
  }
}
