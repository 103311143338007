import { StoreState } from '../store-state';
import { createSelector } from '@ngrx/store';
import { State } from './notification.reducer';
import { NotificationsEffects } from './notifications.effects';

const selectFeature = (state: StoreState) => {
  return state.notifications;
};

export const selectNotifications = createSelector(selectFeature, (state: State) => {
  return state.notifications;
});
