<mat-list>
  <ng-container *ngFor="let item of files; let i = index">
    <mat-list-item>
      <span class="filename">{{ item.name }}</span>
      <div class="file-actions">
        <button class="action-button relative-bottom" mat-raised-button color="warn" (click)="removeFile(i)">
          <mat-icon>delete</mat-icon>{{ 'Common.Delete' | translate }}
        </button>
      </div>
    </mat-list-item>
    <mat-divider></mat-divider>
  </ng-container>
</mat-list>

<div class="d-flex p-40" (drop)="onDrop($event)" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)">
  <div [ngClass]="{
      'files-drop': true,
      'drop-text-parent': true,
      'dashed-container': dragOverStatus !== 'drag-over',
      'dragged-over': dragOverStatus === 'drag-over'
    }">
    <div class="drop-text">
      <span>{{ 'Common.DropHere' | translate }}</span>
    </div>
  </div>
  <div class="relative mb-6">
    <button class="action-button relative-bottom" mat-raised-button color="primary" (click)="fileInput.click()">
      <span>{{ 'Common.AddFile' | translate }}</span>
      <input #fileInput type="file"         multiple
      (change)="addFiles(fileInput.files); fileInput.value = null"
        style="display: none;" />
    </button>
  </div>
</div>
