import { Guid } from 'guid-typescript';
import { AttachmentDto } from './attachment.dto';
import { CommentDto } from './comment.dto';
import { PriorityEnum } from './enums/priority-enum';
import { StatusEnum } from './enums/status-enum';
import { TypeEnum } from './enums/type-enum';

export class ReportDto{
    id: string;
    moduleId: string;
    subject: string;
    description: string;
    comments:CommentDto[];
    redmine: number;
    type: TypeEnum;
    priority: PriorityEnum;
    status: StatusEnum;
    module: any;
    attachments: AttachmentDto[];
    username:string;
    reportNumber: string;

    constructor(
        id: string,
    moduleId: string,
    subject: string,
    description: string,
    comments:CommentDto[],
    redmine: number,
    type: TypeEnum,
    priority: PriorityEnum,
    status: StatusEnum


    ){
        if(id!=null){
        this.id = id;}
        else {this.id = Guid.create().toString()}
        this.moduleId = moduleId;
        this.subject = subject;
        this.status = status;
        if(status==null)
        this.status = 0;
        this.description = description;
        this.comments = comments;
        if(redmine==null)
        this.redmine =0;
        else{
        this.redmine = Number.parseInt(redmine.toString());
        }      
        this.type = type;
        if(type ==null)
        this.type =0;
        this.priority = priority;
        if(priority==null)
        this.priority = 0;

    }


}