import { Action, createReducer, on } from '@ngrx/store';
import { PermissionDictionaryElement } from '@shared/models/permissionDictionaryElement';
import { UserDataDto } from '@shared/models/user-data.dto';
import {
  loadAllClaims,
  loadAllClaimsFailure,
  loadAllClaimsSuccess,
  storeUserData,
  storeUserDataFailure,
  storeUserDataSuccess,
} from './user-data.actions';

export const operationFeatureKey = 'operation';

export interface State {
  userData?: UserDataDto;
  claimsDictionary?: PermissionDictionaryElement[];
}

export const initialState: State = {};

const userDataReducer = createReducer(
  initialState,

  on(storeUserData, (state) => setUserData(state, null)),
  on(storeUserDataSuccess, (state, { data }) => setUserData(state, data)),
  on(storeUserDataFailure, (state, action) => setUserData(state, null)),
  on(loadAllClaims, (state) => setClaimsDictionary(state, null)),
  on(loadAllClaimsSuccess, (state, { data }) => setClaimsDictionary(state, data)),
  on(loadAllClaimsFailure, (state, action) => setClaimsDictionary(state, null))
);

function setUserData(state: State, data: UserDataDto) {
  return {
    ...state,
    userData: data,
  };
}

function setClaimsDictionary(state: State, data: any) {
  return {
    ...state,
    claimsDictionary: data,
  };
}

export function reducer(state: State | undefined, action: Action) {
  return userDataReducer(state, action);
}
