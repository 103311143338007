import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { PageableQuery } from '@shared/models/contracts/pageable-query';
import { PaginatorControl } from '../forms/tables/paginator-control';

@Component({
  selector: 'app-mat-table',
  templateUrl: './mat-table.component.html',
  styleUrls: ['./mat-table.component.scss'],
})
export class MatTableComponent implements OnInit {
  @Input() set total(value: number) {
    this._total = value;
    this.setPaginator();
  }
  get total() {
    return this._total;
  }
  @Input() set heightOffset(value) {
    this._heightOffset = value;
    this.onResize();
  }
  @Input() set filters(value: PageableQuery) {
    this._filters = value;
    this.setPaginator();
  }
  get filters() {
    return this._filters;
  }
  @Input() loading = true;
  @Output() reload = new EventEmitter();
  height: number;
  paginatorControl: PaginatorControl;
  get totalPages() {
    if (this.filters == null) {
      return 0;
    }
    return Math.ceil(this.total / this.filters.pageSize);
  }

  private _heightOffset = 350;
  private _total: number;
  private _filters: PageableQuery;

  constructor() {}

  ngOnInit() {
    this.onResize();
  }

  @HostListener('window:resize')
  onResize() {
    this.height = window.innerHeight - this._heightOffset;
  }

  goto() {
    if (this.paginatorControl.valid) {
      this.filters.pageNumber = this.paginatorControl.value - 1;
      this.doReload();
    }
  }

  doReload() {
    this.reload.emit();
  }

  pageChange(event: PageEvent) {
    this.filters.pageNumber = event.pageIndex;
    this.filters.pageSize = event.pageSize;

    this.paginatorControl.setValue(this.filters.pageNumber + 1);
    this.doReload();
  }

  private setPaginator() {
    if (this.filters != null) {
      this.paginatorControl = new PaginatorControl(this.total, this.filters);
    }
  }
}
