import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { reducers, MetaReducers } from './reducers-index';
import { EffectsModule } from '@ngrx/effects';
import { TablesEffects } from './tables/tables.effects';
import { UserDataEffects } from './user-data/user-data.effects';
import { ReportEffects } from './factures/reports.effects';
import { ModuleEffects } from './module/module.effects';
import { NotificationsEffects } from './notifications/notifications.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forRoot(reducers, {
      metaReducers: MetaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    EffectsModule.forFeature([
      TablesEffects,
      UserDataEffects,
      ReportEffects,
      ModuleEffects,
      NotificationsEffects,
    ]),
  ],
})
export class RootStoreModule {}
