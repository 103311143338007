import { Action, createReducer, on } from '@ngrx/store';
import { PageableDto } from '@shared/models/contracts/pageable.dto';
import { SelectModel } from '@shared/models/contracts/select-model';
import { DictionaryModel } from '@shared/models/dictionary-model';
import { ModuleDto } from '@shared/models/module.dto';
import { loadModules, loadModulesFailure, loadModulesSearchModel, loadModulesSearchModelFailure, loadModulesSearchModelSuccess, loadModulesSuccess, loadPageableModules, loadPageableModulesFailure, loadPageableModulesSuccess } from './module.actions';


export const contractorFeatureKey = 'company';

export interface State {
  modulesSearchModel?: SelectModel<string>[];
  pageableModules?: PageableDto<ModuleDto>;
  modules?: SelectModel<string>[];

}

export const initialState: State = {};

const companyReducer = createReducer(
  initialState,
  on(loadModulesSearchModel, (state) => setModulesSearchModel(state, null)),
  on(loadModulesSearchModelSuccess, (state, { data }) => setModulesSearchModel(state, data)),
  on(loadModulesSearchModelFailure, (state, action) => setModulesSearchModel(state, null)),

  on(loadPageableModules, (state) => setPageableModules(state, null)),
  on(loadPageableModulesSuccess, (state, { data }) => setPageableModules(state, data)),
  on(loadPageableModulesFailure, (state, action) => setPageableModules(state, null)),

  on(loadModules, (state) => state),
  on(loadModulesSuccess, (state, { data }) => setModules(state, data)),
  on(loadModulesFailure, (state) => setModules(state, null))

);



export function reducer(state: State | undefined, action: Action) {
  return companyReducer(state, action);
}

function setPageableModules(state: State, pageableModules: PageableDto<ModuleDto>): State {
  return {
    ...state,
    pageableModules,
  };
}

function setModules(state, modules: SelectModel<string>[]) {
  return { ...state, modules };
}

function setModulesSearchModel(state: State, modulesSearchModel: SelectModel<string>[]): State {
  return {
    ...state,
    modulesSearchModel,
  };
}