import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { ModuleApiCallerService } from '@shared/api-services/module-api-caller.service';
import { ReportApiCallerService } from '@shared/api-services/report-api-caller.service';
import { AuthGuard } from '@shared/guards/auth.guard';
import { AttachmentDto } from '@shared/models/attachment.dto';
import { CommentDto } from '@shared/models/comment.dto';
import { SearchModel } from '@shared/models/contracts/search-model';
import { SelectModel } from '@shared/models/contracts/select-model';
import { AppPermission } from '@shared/models/enums/app-permission.enum';
import { UploadStatus } from '@shared/models/enums/upload-status.enum';
import { ReportDto } from '@shared/models/Report.dto';
import { CommentForm } from '@shared/widgets/forms/facture/commentform';
import { ReportForm } from '@shared/widgets/forms/facture/report.form';
import { addReportAtachments, loadReport, updateReport } from '@store/factures/reports.actions';
import { selectReport } from '@store/factures/reports.selectors';
import { loadModules } from '@store/module/module.actions';
import { selectModules } from '@store/module/module.selectors';
import { StoreState } from '@store/store-state';
import { Guid } from 'guid-typescript';
import { Observable, of } from 'rxjs';
import { filter } from 'rxjs/internal/operators/filter';

@Component({
  selector: 'app-edit-report',
  templateUrl: './edit-report.component.html',
  styleUrls: ['./edit-report.component.scss']
})
@UntilDestroy()
export class EditReportComponent implements OnInit {
  form: ReportForm = new ReportForm();
  filteredModule$: Observable<SelectModel<string>[]>;
  modules: SelectModel<string>[];
  selectedModule: any;
  newComments:ReportForm = new ReportForm();
  addCommentMode=false;
  commentLenght:number;
  attachmentProgress:any;
  addAttachmentsMode: boolean = false;
  uploadStatus = UploadStatus;
  appPermission = AppPermission;





  constructor(
    private store$: Store<StoreState>,
    private dialogRef: MatDialogRef<EditReportComponent>,
    private dialog: MatDialog,
    private authGuard: AuthGuard,
    @Inject(MAT_DIALOG_DATA) public data: { report:ReportDto,types: string[]; priorities: string[], statuses: string[],reportId: string },
    private moduleApiCaller: ModuleApiCallerService,
    private reportApiCaller: ReportApiCallerService

  ) {
    this.loadAllModules();
    this.loadReport();
    if(data.report!=null){
      this.fillForm();
    }
    }

  ngOnInit(): void {
  }

getLabel(){
  if(this.form.get('moduleId').value!=this.data.report?.module?.id)return "Moduł"
  else if(this.form.get('moduleId').dirty)return "Moduł";
  else return this.data.report?.module?.name;
}
  isReadonly(i:Number){
    if(i== this.commentLenght)
    return false;
    else return true;
  }



  get valuesForm() {
    return this.form.get('comments') as FormArray;
  }

  addItem(): void {
    this.addCommentMode=!this.addCommentMode;
    const array = this.form.get('comments') as FormArray;
    array.push(this.createItem());
  }

  createItem() {
    return new CommentForm();
  }
  remove() {
    const array = this.form.get('comments') as FormArray;
    this.addCommentMode=!this.addCommentMode;
    array.removeAt(this.commentLenght);
  }
  close(mode) {
    this.dialogRef.close(mode);
  }

  get attachementsForm() {
    return this.form.get('attachments');
  }

  get commentsForm() {
    return this.form.get('comments') as FormArray;
  }

  filter: (search: string) => void = (search: string) => {
    this.filteredModule$ = this.moduleApiCaller.getClientsSearchModel({ filterWords: search } as SearchModel);
  };

  save(){
    const attachments = this.form.value.attachments as File[];
    this.store$.dispatch(updateReport({data: this.createReport()}))
    if(attachments!=null){
      this.store$.dispatch(addReportAtachments({data: attachments,reportId: this.data.report.id}))
      }
    this.dialogRef.close(true);
  }



  createReport(){
    var comments =[];
    const array = this.form.get('comments') as FormArray;
    var newComment = array.at(array.length-1);
    if(this.form.get('comments').value.length>this.commentLenght){
      comments.push(new CommentDto(Guid.create().toString(), this.form.get('id').value,newComment.get('comments').value.toString() ))
  }

    
    
    return new ReportDto(this.form.get('id').value,this.form.get('moduleId').value,
    this.form.get('subject').value,this.form.get('description').value,
    comments,this.form.get('redmine').value,
    this.form.get('type').value,this.form.get('priority').value,
    this.form.get('status').value);
  }

  pushComments(element: ReportDto) {
    const array = this.form.get('comments') as FormArray;
    element.comments.forEach((el) => {
      const item = new CommentForm();
      item.patchValue({comments:el.comment});
      array.push(item);
    });
  }

  downloadAttachment(attachment: AttachmentDto){
    this.reportApiCaller.getAttachment(attachment.id.toString()).subscribe(res => {
      const blob: any = new Blob([res.body], { type: 'application/force-download' });
      const fileUrl = window.URL.createObjectURL(blob);
      const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
      a.href = fileUrl;
      a.download = attachment.fileName;
      document.body.appendChild(a);
      a.click();        
      document.body.removeChild(a);
      URL.revokeObjectURL(fileUrl);
    });
  }

  removeAttachments(){
    this.changeAttachmentsMode();
    this.form.patchValue({attachments: null});
  }
  changeAttachmentsMode(){
    this.addAttachmentsMode = !this.addAttachmentsMode;
  }

  checkIsInRole(...val: AppPermission[]): boolean {
    return this.authGuard.hasClaims(val);
  }

  loadAllModules(){

    this.store$
    .pipe(
      select(selectModules),
      filter((_) => _ != null),
      untilDestroyed(this)
    )
    .subscribe((data) => {
      this.modules = data ;
    });

    this.store$.dispatch(loadModules());

  }

  loadReport(){
      if(this.data.reportId!=null){
        this.store$.dispatch(loadReport({id: this.data.reportId}))

      this.store$.pipe(select(selectReport),filter((_) => _ != null), untilDestroyed(this)).subscribe((data) => {
       this.data.report = data;
       this.fillForm();
      });
    }
  }

  fillForm(){

    this.commentLenght = this.data.report?.comments?.length;
    this.form.patchValue(this.data.report);
    this.form.patchValue({attachments: null});
    this.pushComments(this.data.report);
    this.filter(undefined);
    this.form.updateValueAndValidity();
  }

  checkIfChanged(){

    return this.checkAttachments() || this.checkComments() || this.checkValues()
  }

  checkAttachments(){
    if(Array.isArray(this.attachementsForm.value)){
      if(this.attachementsForm.value.length>0)
      return true
      else return false;
    }
  }

  checkComments(){
    return this.form.get('comments').value.length>this.commentLenght;
  }

  checkValues(){
    var values = this.form.value;
    var original = this.data.report;
    return original.status!=values.status || original.priority!=values.priority || original.type != values.type
    || original.module.id!=values.moduleId || original.redmine != values.redmine 


  }

}

