<div id="notificationPanel" class="panel">
    <div class='tmp'>
      <div class="note">
        <span class="arrow"></span>
        <div *ngIf="notifications">
          Liczba powiadomień: {{notifications.length}}
        </div>
        <div class="note" *ngFor="let note of notifications">
          {{note.message}}&nbsp;
         <button style="float: right;margin-top: -8px;"
          mat-button matSuffix matTooltip='Historia zgłoszenia'
         mat-icon-button aria-label="Clear" (click)="showHistory(note.parameter)">
         <mat-icon>history</mat-icon></button> 
         &nbsp;
         <button style="float: right;margin-top: -8px;"
         mat-button matSuffix matTooltip='Edycja zgłoszenia'
        mat-icon-button aria-label="Clear" (click)="onEdit(note.parameter)">
        <mat-icon>edit</mat-icon>
      </button>            
        </div>
      </div>
    </div>
  </div>
  

  <!-- <div *ngIf="note.type == 0">
   <a [routerLink]="['/authorised', 'orders-data', 'operations', 'operation', getGuidParameter(note)]"> -->
      <!-- <mat-icon class="color-primary">visibility </mat-icon> -->
    <!-- </a> -->
    <!-- {{ getParameters(note) }} -->

  <!-- </div> --> 