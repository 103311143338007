<form [formGroup]="form">
  <ng-select
    [items]="data$ | async"
    bindLabel="name"
    [addTag]="true"
    [multiple]="false"
    [hideSelected]="true"
    [minTermLength]="2"
    [loading]="dataLoading"
    typeToSearchText="Please enter 2 or more characters"
    [typeahead]="dataInput$"
    [formControlName]="controlName"
  >
  </ng-select>
</form>
