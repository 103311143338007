import { State } from './reports.reducer';
import { StoreState } from '@store/store-state';
import { createSelector } from '@ngrx/store';

const selectFeature = (state: StoreState) => state.pageableReports;

export const selectPageableReports = createSelector(selectFeature, (state: State) => state.pageableReports);

export const selectReport = createSelector(selectFeature, (state: State) => state.report);

export const selectPdf = createSelector(selectFeature, (state: State) => state.pdf);

export const selectReportHistory = createSelector(selectFeature, (state: State) => state.history);

export const selectReportAttachmentsProgress = createSelector(
    selectFeature,
    (state: State) => state.attachmentsProgress
  );
