export enum StatusEnum {

    Reported,
    ApprovedForTrainer,
    ApprovedForVerification,
    Accepted,
    InProgress,
    InTest,
    Rejected,
    Realized,
    Closed,
    RejectedByTrainer,


}