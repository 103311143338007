import { createReducer, Action, on } from '@ngrx/store';
import {
  setPageSizeFailure,
  setPageSizeSuccess,
  setOrderBySuccess,
  setOrderByFailure,
  setSortTypeSuccess,
  setSortTypeFailure,
  setSearchTermSuccess,
  setSearchTermFailure,
  setSelectedStatusesSuccess,
  setSelectedStatusesFailure,
} from './tables.actions';
import { PageSizeMapDto } from 'src/shared/models/config/page-size-map.dto';
import { OrderByMapDto } from 'src/shared/models/config/order-by-map.dto';
import { SortTypeMapDto } from 'src/shared/models/config/sort-type-map.dto';
import { SearchTermMapDto } from '@shared/models/config/search-term-map.dto';
import { StatusEnum } from '@shared/models/enums/status-enum';
import { SelectedStatusesMapDto } from '@shared/models/config/selectedStatusesMapDto';

export interface State {
  pageSize?: PageSizeMapDto[];
  sort?: OrderByMapDto[];
  sortType?: SortTypeMapDto[];
  searchTerm?: SearchTermMapDto[];
  selectedStatuses?: SelectedStatusesMapDto[];
}

export const initialState: State = {};

const tablesReducer = createReducer(
  initialState,

  on(setPageSizeSuccess, (state, { data }) => setPageSizes(state, data)),
  on(setPageSizeFailure, (state) => setPageSizes(state, null)),

  on(setOrderBySuccess, (state, { data }) => setOrderBy(state, data)),
  on(setOrderByFailure, (state) => setOrderBy(state, null)),

  on(setSortTypeSuccess, (state, { data }) => setSortType(state, data)),
  on(setSortTypeFailure, (state) => setSortType(state, null)),

  on(setSearchTermSuccess, (state, { data }) => setSearchTerm(state, data)),
  on(setSearchTermFailure, (state) => setSearchTerm(state, null)),

  on(setSelectedStatusesSuccess, (state, { data }) => setSelectedStatuses(state, data)),
  on(setSelectedStatusesFailure, (state) => setSelectedStatuses(state, null))
);

function setPageSizes(state: State, pageSize) {
  return {
    ...state,
    pageSize,
  };
}

function setOrderBy(state: State, sort) {
  return {
    ...state,
    sort,
  };
}

function setSortType(state: State, sortType) {
  return {
    ...state,
    sortType,
  };
}

function setSearchTerm(state: State, searchTerm) {
  return {
    ...state,
    searchTerm,
  };
}

function setSelectedStatuses(state: State, selectedStatuses) {
  return {
    ...state,
    selectedStatuses,
  };
}

export function reducer(state: State | undefined, action: Action) {
  return tablesReducer(state, action);
}
