import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { PageableQuery } from '../../models/contracts/pageable-query';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { PageableDto } from 'src/shared/models/contracts/pageable.dto';

@Component({
  selector: 'app-selectable-table',
  templateUrl: './selectable-table.component.html',
  styleUrls: ['./selectable-table.component.scss'],
})
export class SelectableTableComponent implements OnInit, OnDestroy {
  @Input() data: PageableDto<any>;
  @Input() nameSelector: (val: any) => string;
  @Output() reload = new EventEmitter<PageableQuery>();

  displayedColumns = ['name'];
  filters = new PageableQuery();
  searchForm = new FormControl();

  constructor() {}

  ngOnDestroy() {}

  ngOnInit() {
    this.searchForm.valueChanges.pipe(debounceTime(500)).subscribe((data) => {
      this.filters.searchTerm = data;
      this.doReload();
    });
  }

  sortData(event: Sort) {
    this.filters.orderBy = event.active;
    this.filters.desc = event.direction === 'desc';
    this.doReload();
  }

  pageChange(event: PageEvent) {
    this.filters.pageNumber = event.pageIndex;
    this.filters.pageSize = event.pageSize;
    this.doReload();
  }

  filter(data) {
    console.log(data);
  }

  private doReload() {
    this.reload.emit(this.filters);
  }
}
