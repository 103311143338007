import { StatusEnum } from "../enums/status-enum";

export class SelectedStatusesMapDto {
    key: string;
    selectedStatuses: StatusEnum[];
  
    constructor(key: string, selectedStatuses: StatusEnum[]) {
      this.key = key;
      this.selectedStatuses = selectedStatuses;
    }
  }