import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EnumTranslateService {
  constructor(private translatePipe: TranslateService) {}

  async translate(value: any, type: any) {
    if (value == null) {
      return value;
    }
    const val = type[value];
    const result = await this.translatePipe
      .get(this.getPrefix(type) + val)
      .pipe(first())
      .toPromise();

    return result;
  }

  private getPrefix(type) {
    if (type == null) {
      throw new Error('Type must be defined');
    }

    const set = enumsPrefixes.get(type);
    if (set == null) {
      throw new Error(`Type is not registered`);
    }

    return `Enums.${set}.`;
  }
}

export const enumsPrefixes = new Map<any, string>([]);
