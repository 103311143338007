<div class="display-flex mt-6">
  <div class="flex-spacer"></div>
  <button class="width-100" [disabled]="type === 'first'" mat-raised-button color="primary" matStepperPrevious>
    {{ 'Common.Prev' | translate }}
  </button>
  <div class="mr-6"></div>
  <button class="width-100" *ngIf="type !== 'last'" mat-raised-button color="primary" matStepperNext>
    {{ 'Common.Next' | translate }}
  </button>
  <button
    [disabled]="!form.valid"
    class="width-100"
    *ngIf="type === 'last'"
    mat-raised-button
    color="warn"
    (click)="onSave()"
  >
    {{ 'Common.Save' | translate }}
  </button>
</div>
