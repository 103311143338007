import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ModuleApiCallerService } from '@shared/api-services/module-api-caller.service';
import { GetPageableQuery } from '@shared/models/queries/get-pageable.query';
import { StoreState } from '@store/store-state';
import { of } from 'rxjs';
import { concatMap, map, catchError, tap } from 'rxjs/operators';
import { addModule, addModuleFailure, addModuleSuccess, deleteModule, deleteModuleFailure, deleteModuleSuccess, loadModules, loadModulesFailure, loadModulesSearchModel, loadModulesSearchModelFailure, loadModulesSearchModelSuccess, loadModulesSuccess, loadPageableModules, loadPageableModulesFailure, loadPageableModulesSuccess } from './module.actions';


@Injectable()
export class ModuleEffects {

    modulesSearchModel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadModulesSearchModel),
      concatMap(({ data }) => {
        return this.moduleApiCaller.getClientsSearchModel(data).pipe(
          map((_) => loadModulesSearchModelSuccess({ data: _ })),
          catchError((error) => of(loadModulesSearchModelFailure({ error })))
        );
      })
    )
  );

  loadModules$ = createEffect(() =>
  this.actions$.pipe(
    ofType(loadModules),
    concatMap(() => {
      return this.moduleApiCaller.getModules().pipe(
        map((_) => loadModulesSuccess({ data: _ })),
        catchError((error) => of(loadModulesFailure({ error })))
      );
    })
  )
);

  deleteModule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteModule),
      concatMap(({ id }) => {
        return this.moduleApiCaller.deleteModule(id).pipe(
          catchError((error) => {
            this.reloadModules();
            return of(deleteModuleFailure({ error }));
          }),
          map((_) => deleteModuleSuccess({ id: id })),
          tap(() => this.reloadModules())
        );
      })
    )
  );

  addModule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addModule),
      concatMap(({ data }) => {
        return this.moduleApiCaller.addModule(data).pipe(
          map((_) => addModuleSuccess({ id: data.id })),
          tap(() => this.reloadModules()),
          catchError((error) => {
            return of(addModuleFailure({ error }));
          })
        );
      })
    )
  );


  loadpageableModules$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPageableModules),
      concatMap(({ filters }) => {
        if(filters){
          this.filters =filters
        }
        return this.moduleApiCaller.getPageableModules(filters).pipe(
          map((_) => loadPageableModulesSuccess({ data: _ })),
          catchError((error) => of(loadPageableModulesFailure({ error })))
        );
      })
    )
  );


  private reloadModules() {
    this.store$.dispatch(loadPageableModules({ filters: this.filters }));
  }
  private filters: GetPageableQuery;
  constructor(
    private actions$: Actions,
    private moduleApiCaller: ModuleApiCallerService,
    private router: Router,
    private store$: Store<StoreState> //private matSnackBar: SnackBarWrapperService
  ) {}

}