import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  AfterContentInit,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Router } from '@angular/router';
import { NavItem } from './nav-item';
import { NavService } from './nav.service';

@Component({
  selector: 'app-menu-list-item',
  templateUrl: './menu-list-item.component.html',
  styleUrls: ['./menu-list-item.component.scss'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({ transform: 'rotate(0deg)' })),
      state('expanded', style({ transform: 'rotate(180deg)' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4,0.0,0.2,1)')),
    ]),
  ],
})
export class MenuListItemComponent implements OnInit, AfterContentInit {
  @ViewChildren('childs') childs: QueryList<ElementRef<MenuListItemComponent>>;
  expanded: boolean;
  isActive: boolean;
  @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;
  @Input() item: NavItem;
  @Input() depth: number;
  get hasChildren() {
    return this.item.children && this.item.children.length;
  }

  constructor(public navService: NavService, public router: Router) {
    if (this.depth == null) {
      this.depth = 0;
    }
  }

  ngOnInit() {
    this.navService.change.subscribe(() => {
      this.expanded = false;
    });

    this.navService.refresh.subscribe(() => {
      this.isElementActive();
    });

    if (this.hasActiveChild()) {
      this.expanded = true;
    }
  }

  ngAfterContentInit() {
    this.isElementActive();
  }

  isElementActive() {
    if (this.router.isActive(this.item.route, true)) {
      this.isActive = true;
    } else {
      if (this.item.children != null) {
        if (this.hasActiveChild()) {
          this.isActive = true;
        } else {
          this.isActive = false;
        }
      } else {
        this.isActive = false;
      }
    }
  }

  hasActiveChild(): boolean {
    let response = false;
    if (this.item != null && this.item.children != null) {
      this.item.children.forEach((child) => {
        if (this.router.isActive(child.route, true)) {
          response = true;
          return;
        }
      });
    }
    return response;
  }

  onItemSelected() {
    if (this.hasChildren) {
      this.expanded = !this.expanded;
    }
  }
}
