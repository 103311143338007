import { registerLocaleData, DatePipe } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localePl from '@angular/common/locales/pl';
import { NgModule, LOCALE_ID, APP_INITIALIZER } from '@angular/core';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthModule, OidcConfigService } from 'angular-auth-oidc-client';
import { environment } from '@env/environment';
import { RootStoreModule } from 'src/root-store/root-store.module';
import { SharedModule } from 'src/shared';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { JwtInterceptor } from 'src/shared/http-interceptors/jwt.interceptor';
import { ErrorInterceptor } from 'src/shared/http-interceptors/error.interceptor';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorIntlPlService } from 'src/shared/services/mat-paginator-intl-pl.service';
import { AuthorisedLayoutComponent } from './layout/authorised-layout/authorised-layout.component';
import { LayoutModule } from '@angular/cdk/layout';
import { UnauthorisedComponent } from './layout/unauthorised/unauthorised.component';
import { AutoLoginComponent } from './layout/auto-login/auto-login.component';
import { HeaderComponent } from './layout/header/header.component';
import { MainMenuComponent } from './layout/main-menu/main-menu.component';

import {
  MatMomentDateModule,
  MomentDateAdapter,
  MAT_MOMENT_DATE_FORMATS,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatIconRegistry } from '@angular/material/icon';
import { FooterComponent } from './footer/footer.component';
import { ModuleCatalogComponent } from './module/module-catalog/module-catalog.component';
import { NotificationComponent } from './layout/notification/notification.component';

registerLocaleData(localePl, 'pl');

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http as any, './assets/i18n/', '.json');
}
export function getBaseUrl() {
  return environment.apiRoot;
}

export function loadConfig(oidcConfigService: OidcConfigService) {
  return () => oidcConfigService.withConfig(environment.identityConfig);
}

@NgModule({
  declarations: [
    AppComponent,
    AuthorisedLayoutComponent,
    UnauthorisedComponent,
    AutoLoginComponent,
    HeaderComponent,
    MainMenuComponent,
    FooterComponent,
    NotificationComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    EffectsModule.forRoot([]),
    RootStoreModule,
    AuthModule.forRoot(),
  ],
  providers: [
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'pl' },
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlPlService },
    DatePipe,
    OidcConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: loadConfig,
      deps: [OidcConfigService],
      multi: true,
    },

    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    this.matIconRegistry.addSvgIcon(`cancel`, `../assets/cancel.svg`);
  }
}
