<div class="app-container" [class.is-mobile]="mobileQuery.matches">
  <app-header [snav]="snav"> </app-header>

  <mat-sidenav-container class="sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
    <mat-sidenav #snav [mode]="'side'" [(opened)]="opened" [fixedInViewport]="mobileQuery.matches" fixedTopGap="56">
      <div class="side-content">
        <app-main-menu class="mb-40"></app-main-menu>
        <div class="spacer"></div>
        <app-footer [opened]="opened"></app-footer>
      </div>
    </mat-sidenav>

    <mat-sidenav-content>
      <div class="shadow-box"></div>
      <div>
        <router-outlet></router-outlet>
      </div>
      <ng-template #notLoggedContent>
        <span>Nie jesteś zalogowany</span>
      </ng-template>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
