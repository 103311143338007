export class CommentDto{

    id:string;
    reportId: string;
    comment: string;
    username:string;

    constructor(id:string,reportId:string,comment:string){

        this.id = id;
        this.reportId = reportId;
        this.comment = comment;
    }
}