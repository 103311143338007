//localhost
// import { OpenIdConfiguration, LogLevel } from 'angular-auth-oidc-client';

// export const environment = {
//   production: false,
//   apiRoot: 'https://localhost:44320',
//   lang: 'pl',
//   apiDateFormat: 'YYYY-MM-DD HH:mm:ss',
//   displayDateFormat: 'YYYY-MM-DD',
//   contractsGeneratorUrl: 'http://localhost:4400',
//   phoneManagementApi: "#",
//   identityConfig: {
//     stsServer: 'https://margiz-identity.devitmcode.pl',
//     redirectUrl: 'http://localhost:4400/#/authorised/dashboard',
//     clientId: 'bug_reporting',
//     responseType: 'code',
//     scope: 'openid profile IdentityServerApi offline_access BugReportingApi',
//     postLogoutRedirectUri: 'http://localhost:4400/#/authorised/dashboard',
//     silentRenew: true,
//     silentRenewUrl: `${window.location.origin}/assets/silent-renew.html`,
//     renewTimeBeforeTokenExpiresInSeconds: 15,
//     postLoginRoute: '/home',
//     forbiddenRoute: '/forbidden',
//     unauthorizedRoute: '/unauthorized',
//     logLevel: LogLevel.Warn,
//     useRefreshToken: true,
//   } as OpenIdConfiguration,
// };

//test
import { OpenIdConfiguration, LogLevel } from 'angular-auth-oidc-client';

export const environment = {
  production: false,
  apiRoot: 'https://margiz-bugreporting-api.devitmcode.pl',
  lang: 'pl',
  apiDateFormat: 'YYYY-MM-DD HH:mm:ss',
  displayDateFormat: 'YYYY-MM-DD',
  contractsGeneratorUrl: 'https://margiz-bugreporting.devitmcode.pl',
phoneManagementApi: "#",
  identityConfig: {
    stsServer: 'https://margiz-identity.devitmcode.pl',
    redirectUrl:
      'https://margiz-bugreporting.devitmcode.pl/#/authorised/dashboard',
    clientId: 'bug_reporting',
    responseType: 'code',
    scope: 'openid profile IdentityServerApi offline_access BugReportingApi',
    postLogoutRedirectUri:
      'https://margiz-bugreporting.devitmcode.pl/#/authorised/dashboard',
    silentRenew: true,
    silentRenewUrl: `${window.location.origin}/assets/silent-renew.html`,
    renewTimeBeforeTokenExpiresInSeconds: 15,
    postLoginRoute: '/home',
    forbiddenRoute: '/forbidden',
    unauthorizedRoute: '/unauthorized',
    logLevel: LogLevel.Warn,
    useRefreshToken: true,
  } as OpenIdConfiguration,
};
