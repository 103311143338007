<mat-card>
  <mat-card-header>
    <mat-card-title>{{ 'Dialogs.Delete.Title' | translate }}</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <span *ngIf="useTranslation">{{ 'Dialogs.Delete.Message' | translate: { value: name | translate } }}</span>
    <span *ngIf="!useTranslation">{{ 'Dialogs.Delete.Message' | translate: { value: name } }}</span>
    <div *ngIf="message!=null">{{message}}</div>

  </mat-card-content>
  <mat-card-actions>
    <div class="d-flex 2-100 ml-20">
      <button id="confirm" color="primary" mat-raised-button (click)="confirm()">
        {{ 'Boolean.true' | translate }}
      </button>
      <button id="cancel" color="warn" mat-raised-button (click)="cancel()">
        {{ 'Boolean.false' | translate }}
      </button>
    </div>
  </mat-card-actions>
</mat-card>
