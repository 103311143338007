import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'contractorSortBy',
})
export class ContractorSortByPipe implements PipeTransform {
  transform(field: string): any {
    switch (field) {
      case 'contractorName': {
        return 'contractor.Name';
      }
      case 'executorName': {
        return 'executor.name';
      }
      case 'executorRepresentativeName': {
        return 'executorRepresentative.name';
      }
      case 'contractType': {
        return 'contractTypeName';
      }
      default: {
        return field;
      }
    }
  }
}
