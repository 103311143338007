import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { PushNotifcationsService } from '@shared/services/push-notifications.service';
import { StoreState } from '@store/store-state';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { NotificationDto } from '../../shared/models/notification.dto';
import {
  loadNotifications,
  loadNotificationsFailure,
  loadNotificationsSuccess,
  markNotificationsAsReceived,
  markNotificationsAsReceivedFailure,
  markNotificationsAsReceivedSuccess,
  receivedNotifications,
  receivedNotificationsFailure,
  receivedNotificationsSuccess,
} from './notification.action';
import { selectNotifications } from './notifications-selectors';

@Injectable()
export class NotificationsEffects {
  loadNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadNotifications),
      concatMap(() => {
        return of(this.notifications).pipe(
          map((_) => loadNotificationsSuccess({ data: _ })),
          catchError((error) => of(loadNotificationsFailure({ error })))
        );
      })
    )
  );

  receivedNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(receivedNotifications),
      concatMap(({ data }) => {
        return of(data).pipe(
          map((_) => receivedNotificationsSuccess({ data: _ })),
          catchError((error) => of(receivedNotificationsFailure({ error })))
        );
      })
    )
  );

  bal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(markNotificationsAsReceived),
      concatMap((data) => {
        return of([] as string[]).pipe(
          map((_) => markNotificationsAsReceivedSuccess({ ids: [] })),
          catchError((error) => of(markNotificationsAsReceivedFailure({ error })))
        );
      })
    )
  );

  markNotificationsAsReceived$ = createEffect(() =>
    this.actions$.pipe(
      ofType(markNotificationsAsReceived),
      withLatestFrom(this.store$.select(selectNotifications)),
      switchMap((action) => {
        let observable: Observable<string[]>;
        let ids: string[];
        const notifications = action[1];
        if (Array.isArray(notifications) && notifications.length > 0) {
          ids = notifications.map((_) => _.id);
          observable = this.pushNotifcationsService.markNotificationsAsReceived(ids);
        } else {
          observable = of([] as string[]);
          ids = [];
        }
        return observable.pipe(
          map((_) => markNotificationsAsReceivedSuccess({ ids: _ })),
          catchError((error) => of(markNotificationsAsReceivedFailure({ error })))
        );
      })
    )
  );

  notifications: NotificationDto[];
  constructor(
    private actions$: Actions,
    private store$: Store<StoreState>,
    private pushNotifcationsService: PushNotifcationsService
  ) {
    this.pushNotifcationsService.startConnection();
    this.pushNotifcationsService.addDataListener().subscribe((_) => {
      this.store$.dispatch(receivedNotifications({ data: _ }));
    });
  }
}
