<mat-toolbar color="primary" class="toolbar">
  <div class="header-left">
    <button mat-icon-button (click)="snav.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
    <a [routerLink]="['']">
      <h1 class="mb-0 app-title">{{ 'App.title' | translate }}</h1>
    </a>
  </div>
  <div class="flex-spacer"></div>
  <div style="display: contents" class="header-right mr-4">
    <button #notificationsButton class="buttonc" (click)="showNotifications()" style="color: black;">
      <div
        *ngIf="notificationsNumber != 0; then showNotificationsIconWithBadge; else showNotificationIconWithoutBadge"
      ></div>
    </button>

    <span *ngIf="isDebug" style="color: black;" class="mr-2 align-middle">{{ roles }}</span>
    <span id="userName" style="color: black;" class="mr-2 align-middle">{{ user }} &nbsp;</span>
    <!-- <button mat-raised-button class="k-button" (click)="logout()">Wyloguj</button> -->
    <div *ngIf="isAuthenticated; else notLoggedButton">
      <button id="logout" style="margin-right: 20px;" mat-raised-button (click)="logout()">Wyloguj</button>
    </div>
    <ng-template id="notLogged" #notLoggedButton>
      <button mat-raised-button (click)="login()">Zaloguj</button>
    </ng-template>
  </div>
  <ng-content></ng-content>
</mat-toolbar>
<ng-template #showNotificationsIconWithBadge>
  <mat-icon class="buttonc" [matBadge]="notificationsNumber" matBadgeColor="warn"> notifications</mat-icon>
</ng-template>

<ng-template #showNotificationIconWithoutBadge>
  <mat-icon class="buttonc"> notifications</mat-icon>
</ng-template>
