import { Injectable } from '@angular/core';
import * as signalR from '@aspnet/signalr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { Subject } from 'rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { HubConnectionState } from '@aspnet/signalr';

@Injectable({
  providedIn: 'root',
})
export class PushNotifcationsService {
  private hubConnection: signalR.HubConnection;
  private headers: HttpHeaders;
  private isAuthorized: boolean;
  private subject: Subject<any>;
  private connectionIsStarting = false;

  constructor(private httpCLient: HttpClient, private oidcSecurityService: OidcSecurityService) {
    this.headers = new HttpHeaders();
    this.headers = this.headers.set('Content-Type', 'application/json');
    this.headers = this.headers.set('Accept', 'application/json');

    this.subject = new Subject<any>();
  }

  public markNotificationsAsReceived(ids: string[]) {
    const subject = new Subject<string[]>();
    this.hubConnection.invoke('MarkNotificationsAsReceived', ids).then((data) => {
      console.log(data);
      subject.next(ids);
    });

    return subject.asObservable();
  }

  public startConnection = () => {
    this.init();
  };

  public addDataListener = () => {
    return this.subject.asObservable();
  };

  private init() {
    this.oidcSecurityService.isAuthenticated$.subscribe((isAuthorized: boolean) => {
      this.isAuthorized = isAuthorized;
      if (isAuthorized) {
        this.initHub();
      }
    });

    console.log('IsAuthorized:' + this.isAuthorized);
  }

  private initHub() {
    if (this.hubConnection == null) {
      const token = this.oidcSecurityService.getToken();
      const url = environment.apiRoot + '/signalR/reportsHub';

      this.hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(url, { accessTokenFactory: () => token })
        .configureLogging(signalR.LogLevel.Information)
        .build();
    }
    if (this.hubConnection.state !== HubConnectionState.Connected && !this.connectionIsStarting) {
      this.connectionIsStarting = true;
      this.hubConnection
        .start()
        .then(() => console.log('Connection started'))
        .then(() => this.hubConnection.invoke('send'))
        .then(() => (this.connectionIsStarting = false))
        .catch((err) => console.log('Error while starting connection: ' + err));
    }

    this.addListeners();
  }

  private addListeners() {
    this.hubConnection.off('send-report-notification');
    this.hubConnection.on('send-report-notification', (data) => {
      const result = data;
      this.subject.next(result);
    });

    // this.hubConnection.off('excel-notification');
    // this.hubConnection.on('excel-notification', (data) => {
    //   const result = data;
    //   this.subject.next(result);
    // });
  }
}
