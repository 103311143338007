import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatOptionSelectionChange } from '@angular/material/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { SelectModel } from 'src/shared/models/contracts/select-model';

@UntilDestroy()
@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
})
export class AutocompleteComponent implements OnInit, OnDestroy {
  @ViewChild(MatAutocompleteTrigger) _auto: MatAutocompleteTrigger;
  @ViewChild('dom', { static: true }) dom;
  @Input() set itemLink(value: any[] | string) {
    if (value != null && ((Array.isArray(value) && value.some((_) => _ == null)) || value.length === 0)) {
      return;
    }

    this._routerLink = value;
  }
  get itemLink() {
    return this._routerLink;
  }
  @Input() form: FormGroup;
  @Input() placeholder: string;
  @Input() filterFunc: (_: string) => Observable<SelectModel<any>[]>;
  @Input() label: string;
  @Input() searchLabel: string;
  @Input() idField = 'id';
  @Input() labelField = 'name';
  @Input() required: boolean = false;
  @Input() set selectedItem(value) {
    this._selectedItem = value;
  }
  get selectedItem() {
    return this._selectedItem;
  }
  @Input() data: SelectModel<any>[];

  searchForm = new FormControl();
  loading = false;

  private _routerLink: any[] | string;
  private _selectedItem;

  constructor() {}

  ngOnDestroy() {}

  ngOnInit() {
    this.validateInputs();
    this.searchForm.valueChanges
      .pipe(
        tap(() => this.setLoading(true)),
        debounceTime(500),
        untilDestroyed(this)
      )
      .subscribe((_) => {
        this.doFilter(_);
      });

      this.getValuesOnInit();
    }
  
    getValuesOnInit(){
      var label = this.form.get(this.labelField).value;
      if(label != null){
        this.searchForm.patchValue(label, { emitEvent: true, onlySelf: true });
      }
    }

  selectionChanged(id, value) {
    this.form.get(this.labelField).patchValue(value, { emitEvent: false, onlySelf: true });
  }

  getFieldValue(data: SelectModel<any>) {
    const result = {};
    result[this.idField] = data.id;
    result[this.labelField] = data.label;

    return result;
  }

  clear() {
    this.searchForm.setValue(null);
    this.form.patchValue(this.getIdValuePair(null, null));
  }

  validateSearch() {
    this.searchForm.updateValueAndValidity();
  }

  validateForm(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const idValid = this.form.get(this.idField).valid;

      return idValid ? null : { idRequired: true };
    };
  }

  public showSelectedItem(data: SelectModel<any>[]): boolean {
    return this._selectedItem != null && (data == null || !data.map((_) => _.id).includes(this._selectedItem.id));
  }

  private getIdValuePair(id, value) {
    let values = {};
    values[this.labelField] = value;
    values[this.idField] = id;

    return values;
  }

  private doFilter(search: string): void {
    this.filterFunc(search);
    this.setLoading(false);
  }

  private setLoading(value: boolean) {
    this.loading = value;
  }

  private validateInputs() {
    if (this.form == null) {
      throw new Error('Input form cannot be null');
    }
  }
}
