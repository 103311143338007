import { Component, OnInit, Input } from '@angular/core';
import { RangeDto } from '../../models/range.dto';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-range-picker',
  templateUrl: './range-picker.component.html',
  styleUrls: ['./range-picker.component.scss'],
})
export class RangePickerComponent implements OnInit {
  @Input() form: FormControl;
  range = new RangeDto();
  constructor() {}

  ngOnInit(): void {}

  valueChanged() {
    this.form.setValue(this.range.toTimeSpan());
  }
}
