import { PipeTransform, Pipe } from '@angular/core';
import { EnumTranslateService } from '../services/enum-translate.service';
import { CommonModule, AsyncPipe } from '@angular/common';

@Pipe({
  name: 'enumTranslate'
})
export class EnumTranslatePipe implements PipeTransform {
  constructor(private enumTranslateService: EnumTranslateService) {}

  transform(value: any, type) {
    return this.enumTranslateService.translate(value, type);
  }
}
