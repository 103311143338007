import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'serviceGroupNameSortBy',
})
export class ServiceGroupNameSortByPipe implements PipeTransform {
  transform(field: string): any {
    switch (field) {
      case 'serviceGroupName': {
        return 'serviceGroup.name';
      }
      case 'contractType': {
        return 'contractTypeName';
      }
      default: {
        return field;
      }
    }
  }
}
