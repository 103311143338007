export enum AppPermission {
  UserRead = 40,
  UserChange = 41,
  RoleRead = 50,
  RoleChange = 51,

  ClientRead = 60,
  ClientChange = 61,
  ClientEditNotes = 62,

  ResourceRead = 70,
  ResourceChange = 71,

  OrderRead = 80,
  OrderChange = 81,
  OrderReadForAllCompanies = 82,
  OrderReadOwn = 83,
  OrderReadOwnCompany = 84,

  OrderTypesEdit = 90,
  OrderWorkflowsEdit = 91,
  OrderStatusesEdit = 92,

  CreateEmployee = 100,
  ReadEmployee = 101,
  UpdateEmployee = 102,
  DeleteEmployee = 103,
  ReadEmployeesForCompany = 104,

  CanChangeOrderStatus = 110,
  CanChangeOrderStatusFull = 111,

  CompanyNotesEdit = 150,
  CompanyContactEdit = 151,

  InvoiceRead = 2100,
  InvoiceChange = 2101,
  ClientsRead = 2102,
  ClientsChange = 2103,

  ReadOwnReports = 2201,
  ReadAllReports = 2202,
  ReportsEdit = 2203,
  ModulesChange = 2204,

  AccessAll = 32767,
}
