import { createAction, props } from '@ngrx/store';
import { PageableDto } from '@shared/models/contracts/pageable.dto';
import { SearchModel } from '@shared/models/contracts/search-model';
import { SelectModel } from '@shared/models/contracts/select-model';
import { DictionaryModel } from '@shared/models/dictionary-model';
import { ModuleDto } from '@shared/models/module.dto';

export const loadModulesSearchModel = createAction(
    '[Company] Load Modules SearchModel',
    props<{ data: SearchModel }>()
  );
  
  export const loadModulesSearchModelSuccess = createAction(
    '[Company] Load Modules SearchModel Success',
    props<{ data: SelectModel<string>[] }>()
  );
  
  export const loadModulesSearchModelFailure = createAction(
    '[Company] Load Modules SearchModel Failure',
    props<{ error: any }>()
  );


export const addModule = createAction('[Module] Add Module', props<{ data: ModuleDto }>());

export const addModuleSuccess = createAction('[Module] Add Module Success', props<{ id: string }>());

export const addModuleFailure = createAction('[Module] Add Module Failure', props<{ error: any }>());

export const deleteModule = createAction('[Module] Delete Module', props<{ id: string }>());

export const deleteModuleSuccess = createAction('[Module] Delete Module Success', props<{ id: string }>());

export const deleteModuleFailure = createAction('[Module] Delete Module Failure', props<{ error: any }>());

export const loadPageableModules = createAction(
  '[Modules] Load Pageable Modules',
  props<{ filters? : any }>()
);

export const loadPageableModulesSuccess = createAction(
  '[Modules] Load Pageable Modules Success',
  props<{ data: PageableDto<ModuleDto> }>()
);

export const loadPageableModulesFailure = createAction(
  '[Modules] Load Pageable Modules Failure',
  props<{ error: any }>()
);

export const loadModules = createAction('[Dictionaries] LoadModules Modules');

export const loadModulesSuccess = createAction(
  '[Dictionaries] Load Modules Success',
  props<{ data: SelectModel<string>[] }>()
);

export const loadModulesFailure = createAction(
  '[Dictionaries] Load Modules Failure',
  props<{ error: any }>()
);