<div class="display-flex">
  <mat-form-field class="ml-8 mr-4">
    <mat-icon matPrefix>search</mat-icon>
    <mat-label>{{ 'Common.Search' | translate }}</mat-label>
    <input matInput [formControl]="searchForm" #searchInput />
    <button
      mat-button
      *ngIf="searchForm.value"
      matSuffix
      mat-icon-button
      aria-label="Clear"
      (click)="searchForm.setValue(null)"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
  <div class="flex-spacer"></div>
  <mat-paginator
    *ngIf="data?.total > 25"
    class="width-50p"
    [length]="data ? data.total : 0"
    [pageSize]="filters.pageSize"
    [pageSizeOptions]="[25]"
    (page)="pageChange($event)"
  >
  </mat-paginator>
</div>
<table mat-table [dataSource]="data ? data.result : []" matSort class="table width-100p">
  <!-- <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <mat-checkbox></mat-checkbox>
    </td>
  </ng-container> -->

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">{{ nameSelector(element) }}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
