export class RangeDto {
  days = 0;
  hours = 0;
  minutes = 0;

  constructor() {}

  toTimeSpan() {
    this.correctValues();

    return `${this.days}:${this.hours}:${this.minutes}:00`;
  }

  private correctValues() {
    if (this.minutes >= 60) {
      const hours = Math.floor(this.minutes / 60);
      this.hours += hours;
      this.minutes %= 60;
    }
    if (this.hours >= 24) {
      const days = Math.floor(this.hours / 24);
      this.days += days;
      this.hours %= 24;
    }
  }
}
