import { createAction, props } from '@ngrx/store';
import { NotificationDto } from '../../shared/models/notification.dto';

export const loadNotifications = createAction('[Notifications] Load Notifications');

export const loadNotificationsSuccess = createAction(
  '[Notifications] Load Notifications Success',
  props<{ data: NotificationDto[] }>()
);

export const loadNotificationsFailure = createAction(
  '[Notifications] Load Notifications Failure',
  props<{ error: any }>()
);

export const receivedNotifications = createAction(
  '[Notifications] Received Notifications',
  props<{ data: NotificationDto[] }>()
);

export const receivedNotificationsSuccess = createAction(
  '[Notifications] Received Notifications Success',
  props<{ data: NotificationDto[] }>()
);

export const receivedNotificationsFailure = createAction(
  '[Notifications] Received Notifications Failure',
  props<{ error: any }>()
);

export const markNotificationsAsReceived = createAction('[Notifications] Mark Notifications as Received');

export const markNotificationsAsReceivedSuccess = createAction(
  '[Notifications] Mark Notifications as Received Success',
  props<{ ids: string[] }>()
);

export const markNotificationsAsReceivedFailure = createAction(
  '[Notifications] Mark Notifications as Received Failure',
  props<{ error: any }>()
);
