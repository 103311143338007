import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SnackBarWrapperService } from '../services/snack-bar-wrapper.service';

import { OidcSecurityService } from 'angular-auth-oidc-client';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private oidcSecurityService: OidcSecurityService, private _snackBar: SnackBarWrapperService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401) {
          // auto logout if 401 response returned from api

          this._snackBar.openMessage('Nie zalogowany', 'error');
          this.oidcSecurityService.forceRefreshSession();
        } else if (err.status === 403) {
          this._snackBar.openMessage('Brak uprawnienń', 'error');
        } else if (err.status >= 400) {
          this.showErrors(err.error);
        }

        return throwError(err);
      })
    );
  }

  private showErrors(data) {
    let errors: string;
    if (data.errors != null) {
      errors = data.errors.join('<br />');
    } else {
      errors = data.Errors.join('<br />');
    }

    this._snackBar.openMessage(errors, 'error');
  }
}
