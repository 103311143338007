<div class="centerize">
  <ng-container [ngSwitch]="contact">
    <ng-container *ngSwitchCase="contactType.Phone">
      <mat-icon *ngIf="showIcon">phone</mat-icon>
      <span *ngIf="showLabel">{{ 'Contacts.Phone' | translate }}</span>
    </ng-container>
    <ng-container *ngSwitchCase="contactType.Email">
      <mat-icon *ngIf="showIcon">email</mat-icon>
      <span *ngIf="showLabel">{{ 'Contacts.Email' | translate }}</span>
    </ng-container>
    <ng-container *ngSwitchCase="contactType.Fax">
      <mat-icon *ngIf="showIcon">description</mat-icon>
      <span *ngIf="showLabel">{{ 'Contacts.Fax' | translate }}</span>
    </ng-container>
    <ng-container *ngSwitchCase="contactType.Address">
      <mat-icon *ngIf="showIcon">room</mat-icon>
      <span *ngIf="showLabel">{{ 'Contacts.Address' | translate }}</span>
    </ng-container>
    <ng-container *ngSwitchCase="contactType.Website">
      <mat-icon *ngIf="showIcon">language</mat-icon>
      <span *ngIf="showLabel">{{ 'Contacts.Website' | translate }}</span>
    </ng-container>
  </ng-container>
</div>
